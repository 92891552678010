<template>
    <div class="alert-wrapper">
        <div class="custom-alert">
            <i class="fa fa-check-circle" style="font-size:80px"></i>
            <h6>Thank you for reaching out to us. Someone will get in touch with you shortly. Meanwhile, you can also write
            to us at tech@thinkalternate.com</h6>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AlertVue'
}
</script>
  
<style>
.alert-wrapper {
    position: fixed;
    width: 100%;
    top: 20px;
}

.custom-alert {
    padding: 20px;
    text-align: center;
    color: rgb(7, 178, 30);
    background:  rgb(247, 245, 245);
    border-radius: 10px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    height: 200px;
    margin: 60px auto;
}
</style>