import { createApp } from "vue";
// import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Meta from "vue-meta";
// import VueHotjar from "vue-hotjar";

// Vue.config.productionTip = false;

// Vue.use(VueHotjar, {
//   id: "3862011", // Replace with your Hotjar tracking ID
//   isProduction: true,
//   snippetVersion: 6,
// });

createApp(App).use(router, Meta).mount("#app");
