<template>
   <div class="container" id="case_study">
    <div class="h1">Testimonials</div>
      <div class="h6 mb-5">
        See what our clients have to say
      </div>
      <!-- <h1 class="carousel-title">Testimonials</h1>
      <p class="carousel-subtitle">See what our clients have to say</p> -->
    </div>
  <div class="carousel-wrapper">
    <!-- Vertical Carousel -->
    <div class="carousel-container">
      <div class="carousel-track vertical-track">
        <div
          v-for="(card, index) in testimonialsAuthorDetails"
          :key="index"
          :class="['carousel-card', getClass(index, 'vertical')]"
          :style="getVerticalStyle(index)"
        >
          <div class="card-content">
            <div>
              <div class="person-header">
                <img
                  :src="testimonialsAuthorDetails[index].authorImage"
                  alt="Person"
                  class="person-image"
                />
                <div sty>
                  <h5 style="font-weight: 700;font-size: 2rem;margin-top: 10px">
                    {{ testimonialsAuthorDetails[index].author }}
                  </h5>
                  <h3 style="font-weight: 500; font-size: 1.4rem">
                    {{ testimonialsAuthorDetails[index].authorDesignation }}
                  </h3>
                </div>
              </div>
              <p style="margin-top: 10px;" class="description">
                {{ testimonialsAuthorDetails[index].authorWords }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

       <!-- Horizontal Carousel -->
    <div class="carousel-container">
      <div class="carousel-track horizontal-track">
        <div
          v-for="(card, index) in testimonials"
          :key="index"
          :class="['carousel-card', getClass(index, 'vertical')]"
          :style="getHorizontalStyle(index)"
        >
          <div class="card-content">
            <div>
              <img
                :src="testimonials[index].companyLogo"
                alt="Company Logo"
                class="company-image"
              />
              <h3 style="font-weight: bold;font-size: 2.2rem">
                {{ testimonials[index].companyName }}
              </h3>
              <h5 style="margin-bottom: 10px; font-weight: 500;font-size: 1.5rem;">
                {{ testimonials[index].title }}
              </h5>

               <!-- Star Rating Section -->
              <div class="star-rating">
                <span v-for="star in 5" :key="star" class="star">
                  <i
                    :class="{
                      'fa fa-star': star <= testimonials[index].rating,
                      'fa fa-star-o': star > testimonials[index].rating,
                    }"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>

              <p class="description" v-html="testimonials[index].message"></p>
              <ul class="company-points">
                <li
                  v-for="(point, index) in testimonials[index].points"
                  :key="index"
                >
                  {{ point }}
                </li>
              </ul>
              <a href="#contact_us">
                <button
                  style="background-color: var(--primary-color); color: #ffffff"
                  class="btn btn-primary text-uppercase b-width"
                  @click="handleClick(index)"
                >
                  TELL ME HOW YOU DID IT
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          companyLogo: require("../assets/images/2.png"), // Example image URL
          companyName: "Mental Health & Fitness",
          title:
            "A user-friendly Mobile App. An Operations friendly Web App. A Responsive Website.",
          message:
            "iDare transformed the mental health industry by helping people recognize, prevent and fight against abuse with a holistic app. <br> iDare saved a Kashmiri teenager from domestic abuse with this App!",
          points: [
            "93K App Launches",
            "4 mins 5 sec Avg Engagement time",
            "8K + unique global downloads",
          ],
           rating: 4, // 4-star rating
        },
        {
          companyLogo: require("../assets/home_page_icons/hoc_icon.svg"),
          companyName: "Luxury eCommerce",
          title:
            "The ULTIMATE, tailor-made, and secure Web eCommerce Marketplace..",
          message:
            "House of creativity takes the art industry online by introducing one of a kind design and highly secure marketplace. From guest checkout to insuring art on transit everything is taken care.The concept completely transforms the way art is purchased.<br>Customers have bought single artwork as high as $5K USD!",
          points: [
            "Design Driven Development",
            "Responsive Web Ap",
            "Multi-currency and global shipment",
          ],
           rating: 5, // 4-star rating
        },
        {
          companyLogo: require("../assets/images/flash_n_tap_logo.svg"),
          companyName: "Retail Technology",
          title:
            "Game- Changing, light-weight, user friendly and cost effective mobile app.",
          message:
            "One of the most innovative products in Canada. It is an IoT based product with an IoT device, mobile and web app. Customers visiting places like Target or Walmart stores can tap their phone to receive invoices, track expenses and showcase saving and cheaper alternative stores.",
          points: [
            "Native Android and iOS apps",
            "BLE and NFC technology",
            "IBM backed product",
          ],
           rating: 5, // 4-star rating
        },
        {
          companyLogo: require("../assets/images/pathfinder_logo.svg"),
          companyName: "PharmaTech",
          // title: "Game- Changing, light-weight, user friendly and cost effective mobile app.",
          message:
            "Pathfinder Business intelligence analytics can fundamentally disrupt current disease management, allowing a higher degree of predictability in care delivery and better insights into resource allocation.",
          points: [
            "Quick Scalable prototype",
            "Cross-platform Mobile App.",
            "Flutter Framework.",
          ],
           rating: 4, // 4-star rating
        },
        {
          companyLogo: require("../assets/images/ambimat_logo.png"),
          companyName: "IoT Technology",
          // title: "Game- Changing, light-weight, user friendly and cost effective mobile app.",
          message:
            "Ambimat’s smart heat sensor technology helps various businesses in view and recording temperature of cold storage, hot storage and other temperature sensitive products. The app uses BLE and NFC technology for communication..",
          points: [
            "Hybrid App with heat sensor IOT Module.",
            "Xamarin Mobile App Framework",
          ],
           rating: 4, // 4-star rating
        },
      ],

      testimonialsAuthorDetails: [
        {
          author: "Vaamaa Baldota",
          authorDesignation: "CEO, iDare Bengaluru",
          authorWords:
            "Good Team! Committed to your mission! Quality of work! The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds.Really enjoyed working with ThinkAlt! Would highly recommend them.",
          authorImage: require("../assets/client_images/idare.png"),
        },
        {
          author: "Luv Sinha",
          authorDesignation: "CEO, House of Creativity Mumbai- India",
          authorWords:
            "The team was helpful and went out of their way at times. Their support and efficiency are the reasons why we would recommend them. Their support and efficiency are the reasons why we would recommend them.",
          authorImage: require("../assets/images/house_of_creativity.png"),
        },
        {
          author: "Venkata G Thota",
          authorDesignation: "COO, Apeirogon Labs Canada",
          authorWords:
            "Technically strong team that listen and understand what we want and timely deliverables. App is successfully completed with backend support. Friendly knowledge transfer to our in-house team. Top 3 reasons to recommend- Talent, Behavior and Focus",
          authorImage: require("../assets/images/apeir_labs.png"),
        },
        {
          author: "Anand Srinivasan",
          authorDesignation: "Weathervane Pte Ltd",
          authorWords:
            "They are an Exceptional team with clear leadership. We are impressed by their commitment, speed and knowledge. Quality of delivery, Design skills and Seamless integration of desktop to mobile environment are the reasons for our success story.",
          authorImage: require("../assets/images/profile_placeholder.png"),
        },
        {
          author: "Neil Shah",
          authorDesignation: "Ambimat Electronics",
          authorWords:
            "They have a good core team and access to developers with a variety of skill set. ThinkAlt has a good team! Keep up the good work. Unexpected benefit is the fact that a Xamarin developer was available with ThinkAlt",
          authorImage: require("../assets/images/profile_placeholder.png"),
        },
      ],
      currentHorizontalSlide: 0,
      currentVerticalSlide: 0,
      transitionSpeed: 1,
      autoSlideDuration: 5000, // Time between slides (milliseconds)
      horizontalIntervalId: null,
      verticalIntervalId: null,
    };
  },
  methods: {
    getClass(index, type) {
      const position =
        type === "horizontal"
          ? index - this.currentHorizontalSlide
          : index - this.currentVerticalSlide;
      if (position === 0) return "active";
      if (Math.abs(position) === 1) return "nearby";
      return "far";
    },
    getHorizontalStyle(index) {
      const position = index - this.currentHorizontalSlide;
      const xOffset = position * 100;
      const scale = Math.abs(position) === 1 ? 0.85 : 0.7;
      return {
        transform: `translateX(${xOffset}%) scale(${scale})`,
        transition: `transform ${this.transitionSpeed}s ease`,
      };
    },
    getVerticalStyle(index) {
      const position = index - this.currentVerticalSlide;
      const yOffset = position * 100;
      const scale = Math.abs(position) === 1 ? 0.85 : 0.7;
      return {
        transform: `translateY(${yOffset}%) scale(${scale})`,
        transition: `transform ${this.transitionSpeed}s ease`,
      };
    },
    nextHorizontalSlide() {
      this.currentHorizontalSlide =
        (this.currentHorizontalSlide + 1) %
        this.testimonialsAuthorDetails.length;
    },
    nextVerticalSlide() {
      this.currentVerticalSlide =
        (this.currentVerticalSlide + 1) % this.testimonials.length;
    },
    startHorizontalAutoSlide() {
      this.horizontalIntervalId = setInterval(
        this.nextHorizontalSlide,
        this.autoSlideDuration
      );
    },
    startVerticalAutoSlide() {
      this.verticalIntervalId = setInterval(
        this.nextVerticalSlide,
        this.autoSlideDuration
      );
    },
    stopHorizontalAutoSlide() {
      clearInterval(this.horizontalIntervalId);
    },
    stopVerticalAutoSlide() {
      clearInterval(this.verticalIntervalId);
    },
  },
  mounted() {
    this.startHorizontalAutoSlide();
    this.startVerticalAutoSlide();
  },
  beforeUnmount() {
    this.stopHorizontalAutoSlide();
    this.stopVerticalAutoSlide();
  },
};
</script>

<style scoped>
.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
  max-width: 100%; /* Ensure the carousel does not overflow */
  overflow-x: hidden; /* Hide overflow on the x-axis */
}

.carousel-header {
  text-align: center;
  margin-bottom: 20px; /* Add space between header and carousel */
}

.carousel-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #333; /* Adjust the color as needed */
  margin: 0;
}

.carousel-subtitle {
  font-size: 1.2em;
  color: #666; /* Lighter color for the subtitle */
  margin: 10px 0;
}

.carousel-container {
  width: 100%; /* Set width to 100% to fit within parent container */
  max-width: 600px; /* You can adjust this to your desired width */
  height: 500px;
  overflow: hidden;
  position: relative;
}

.carousel-track {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.horizontal-track {
  flex-direction: row;
}

.vertical-track {
  flex-direction: column;
}

.carousel-card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.card-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 10px;
  padding: 20px; /* Increase padding */
}

.carousel-card h3 {
  margin-bottom: 15px;
  color: black;
  opacity: 1; /* Ensure text remains fully visible */
}

ul.company-points {
  margin-top: 15px; /* Add more space above the list */
  padding-left: 30px; /* Indent list points for readability */
}

ul.company-points li {
  font-size: 1.3rem;
  margin-bottom: 0px; /* Increase space between list items */
}

/* Active card style */
.carousel-card.active .card-content {
  background-color: rgb(
    255,
    255,
    255
  ); /* Fully opaque background for active card */
}

/* Nearby and far cards (scaled and faded background) */
/* .carousel-card.nearby .card-content {
  background-color: rgba(234, 234, 234, 0.7);
} */

.carousel-card.far .card-content {
  background-color: rgba(234, 234, 234, 0.5);
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .carousel-wrapper {
    flex-direction: column;
    gap: 30px;
  }

  .carousel-container {
    width: 420px;
    height: 350px;
  }

  .carousel-card h3 {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .carousel-container {
    width: 420px;
    height: 350px;
  }

  .carousel-card h3 {
    font-size: 10px;
  }
}

/* Left card company info */
.company-info {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items vertically */
}

.company-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

h4 {
  margin: 10px 0;
}

.company-points {
  list-style-type: disc;
  padding-left: 20px;
}

/* Right card person info */
.person-info {
  text-align: left;
  font-weight: 400;
}

.person-header {
  display: flex;
  align-items: center;
}

.person-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.description {
  line-height: 1.4 !important;
  font-size: 1.4em;
  color: #000;
}

.star-rating {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.star i {
  color: gold; /* Set the color for the stars */
  font-size: 1.2em; /* Adjust size of the stars */
}

.star i.fa-star-o {
  color: #ccc; /* Grey out empty stars */
}
</style>
