<template>
  <div id="contact_us"></div>
  <section class="pb-0" style="
      background-color: #231f20;
      margin-top: 3rem;
      padding-top: 0px;
      height: 40rem;
      margin-bottom: 5rem;
    ">
    <div class="contact_form_container">
      <div style="
          background-color: white;
          right: 10;
          position: absolute;
          /* right: 65%; */
          margin-top: 40px;
          margin-bottom: 20px;
          opacity: 1;
        " :style="{
          right:deviceType == 'mobile' ? '0' : '-65%',
          width: deviceType == 'mobile' ? '90%' : '360px',
          padding: deviceType == 'mobile' ? '10px' : '15px',
        }" class="content-center box stack-top">
        <!-- <h2>Contact Us.</h2> -->
        <p class="text-dark">Schedule a free consultation.</p>
        <div class="row">
          <div class="col-5">
            <hr />
          </div>
          <div class="col-2" style="margin-top: -10px">
            <img src="../assets/icons/down_pointing_arrow.svg" style="max-width: 20px; min-width: 20px" />
          </div>
          <div class="col-5">
            <hr />
          </div>
        </div>
        <div class="footer-box">
          <div style="position: relative;" class="app">
            <div class="cover">

              <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
              <ul>
                <li style="color: #f90505;" v-for="(error, index) in errors" :key="error" :data-index="index">{{ error }}
                </li>
              </ul>
              <button class="contact-form-button next" type="button" @click="onValidationClose()">
                Close
              </button>
              </p>

            </div>

            <div class="tab">
              <!-- <div class="row">

                <div class="col-6">
                 
                </div>
                <div class="col-6">
                </div>
              </div> -->
              <input type="text" v-model="name" placeholder="Full Name" />
              <input type="email" v-model="email" placeholder="Email" />
              <div class="row1">
                <div class="column1">
                  <select v-model="country" name="countryCode" id="">
                    <option data-countryCode="IN" value="91">
                      India (+91)
                    </option>
                    <optgroup label="Other countries">
                      <option data-countryCode="DZ" value="213">
                        Algeria (+213)
                      </option>
                      <option data-countryCode="AD" value="376">
                        Andorra (+376)
                      </option>
                      <option data-countryCode="AO" value="244">
                        Angola (+244)
                      </option>
                      <option data-countryCode="AI" value="1264">
                        Anguilla (+1264)
                      </option>
                      <option data-countryCode="AG" value="1268">
                        Antigua &amp; Barbuda (+1268)
                      </option>
                      <option data-countryCode="AR" value="54">
                        Argentina (+54)
                      </option>
                      <option data-countryCode="AM" value="374">
                        Armenia (+374)
                      </option>
                      <option data-countryCode="AW" value="297">
                        Aruba (+297)
                      </option>
                      <option data-countryCode="AU" value="61">
                        Australia (+61)
                      </option>
                      <option data-countryCode="AT" value="43">
                        Austria (+43)
                      </option>
                      <option data-countryCode="AZ" value="994">
                        Azerbaijan (+994)
                      </option>
                      <option data-countryCode="BS" value="1242">
                        Bahamas (+1242)
                      </option>
                      <option data-countryCode="BH" value="973">
                        Bahrain (+973)
                      </option>
                      <option data-countryCode="BD" value="880">
                        Bangladesh (+880)
                      </option>
                      <option data-countryCode="BB" value="1246">
                        Barbados (+1246)
                      </option>
                      <option data-countryCode="BY" value="375">
                        Belarus (+375)
                      </option>
                      <option data-countryCode="BE" value="32">
                        Belgium (+32)
                      </option>
                      <option data-countryCode="BZ" value="501">
                        Belize (+501)
                      </option>
                      <option data-countryCode="BJ" value="229">
                        Benin (+229)
                      </option>
                      <option data-countryCode="BM" value="1441">
                        Bermuda (+1441)
                      </option>
                      <option data-countryCode="BT" value="975">
                        Bhutan (+975)
                      </option>
                      <option data-countryCode="BO" value="591">
                        Bolivia (+591)
                      </option>
                      <option data-countryCode="BA" value="387">
                        Bosnia Herzegovina (+387)
                      </option>
                      <option data-countryCode="BW" value="267">
                        Botswana (+267)
                      </option>
                      <option data-countryCode="BR" value="55">
                        Brazil (+55)
                      </option>
                      <option data-countryCode="BN" value="673">
                        Brunei (+673)
                      </option>
                      <option data-countryCode="BG" value="359">
                        Bulgaria (+359)
                      </option>
                      <option data-countryCode="BF" value="226">
                        Burkina Faso (+226)
                      </option>
                      <option data-countryCode="BI" value="257">
                        Burundi (+257)
                      </option>
                      <option data-countryCode="KH" value="855">
                        Cambodia (+855)
                      </option>
                      <option data-countryCode="CM" value="237">
                        Cameroon (+237)
                      </option>
                      <option data-countryCode="CA" value="1">
                        Canada (+1)
                      </option>
                      <option data-countryCode="CV" value="238">
                        Cape Verde Islands (+238)
                      </option>
                      <option data-countryCode="KY" value="1345">
                        Cayman Islands (+1345)
                      </option>
                      <option data-countryCode="CF" value="236">
                        Central African Republic (+236)
                      </option>
                      <option data-countryCode="CL" value="56">
                        Chile (+56)
                      </option>
                      <option data-countryCode="CN" value="86">
                        China (+86)
                      </option>
                      <option data-countryCode="CO" value="57">
                        Colombia (+57)
                      </option>
                      <option data-countryCode="KM" value="269">
                        Comoros (+269)
                      </option>
                      <option data-countryCode="CG" value="242">
                        Congo (+242)
                      </option>
                      <option data-countryCode="CK" value="682">
                        Cook Islands (+682)
                      </option>
                      <option data-countryCode="CR" value="506">
                        Costa Rica (+506)
                      </option>
                      <option data-countryCode="HR" value="385">
                        Croatia (+385)
                      </option>
                      <option data-countryCode="CU" value="53">
                        Cuba (+53)
                      </option>
                      <option data-countryCode="CY" value="90392">
                        Cyprus North (+90392)
                      </option>
                      <option data-countryCode="CY" value="357">
                        Cyprus South (+357)
                      </option>
                      <option data-countryCode="CZ" value="42">
                        Czech Republic (+42)
                      </option>
                      <option data-countryCode="DK" value="45">
                        Denmark (+45)
                      </option>
                      <option data-countryCode="DJ" value="253">
                        Djibouti (+253)
                      </option>
                      <option data-countryCode="DM" value="1809">
                        Dominica (+1809)
                      </option>
                      <option data-countryCode="DO" value="1809">
                        Dominican Republic (+1809)
                      </option>
                      <option data-countryCode="EC" value="593">
                        Ecuador (+593)
                      </option>
                      <option data-countryCode="EG" value="20">
                        Egypt (+20)
                      </option>
                      <option data-countryCode="SV" value="503">
                        El Salvador (+503)
                      </option>
                      <option data-countryCode="GQ" value="240">
                        Equatorial Guinea (+240)
                      </option>
                      <option data-countryCode="ER" value="291">
                        Eritrea (+291)
                      </option>
                      <option data-countryCode="EE" value="372">
                        Estonia (+372)
                      </option>
                      <option data-countryCode="ET" value="251">
                        Ethiopia (+251)
                      </option>
                      <option data-countryCode="FK" value="500">
                        Falkland Islands (+500)
                      </option>
                      <option data-countryCode="FO" value="298">
                        Faroe Islands (+298)
                      </option>
                      <option data-countryCode="FJ" value="679">
                        Fiji (+679)
                      </option>
                      <option data-countryCode="FI" value="358">
                        Finland (+358)
                      </option>
                      <option data-countryCode="FR" value="33">
                        France (+33)
                      </option>
                      <option data-countryCode="GF" value="594">
                        French Guiana (+594)
                      </option>
                      <option data-countryCode="PF" value="689">
                        French Polynesia (+689)
                      </option>
                      <option data-countryCode="GA" value="241">
                        Gabon (+241)
                      </option>
                      <option data-countryCode="GM" value="220">
                        Gambia (+220)
                      </option>
                      <option data-countryCode="GE" value="7880">
                        Georgia (+7880)
                      </option>
                      <option data-countryCode="DE" value="49">
                        Germany (+49)
                      </option>
                      <option data-countryCode="GH" value="233">
                        Ghana (+233)
                      </option>
                      <option data-countryCode="GI" value="350">
                        Gibraltar (+350)
                      </option>
                      <option data-countryCode="GR" value="30">
                        Greece (+30)
                      </option>
                      <option data-countryCode="GL" value="299">
                        Greenland (+299)
                      </option>
                      <option data-countryCode="GD" value="1473">
                        Grenada (+1473)
                      </option>
                      <option data-countryCode="GP" value="590">
                        Guadeloupe (+590)
                      </option>
                      <option data-countryCode="GU" value="671">
                        Guam (+671)
                      </option>
                      <option data-countryCode="GT" value="502">
                        Guatemala (+502)
                      </option>
                      <option data-countryCode="GN" value="224">
                        Guinea (+224)
                      </option>
                      <option data-countryCode="GW" value="245">
                        Guinea - Bissau (+245)
                      </option>
                      <option data-countryCode="GY" value="592">
                        Guyana (+592)
                      </option>
                      <option data-countryCode="HT" value="509">
                        Haiti (+509)
                      </option>
                      <option data-countryCode="HN" value="504">
                        Honduras (+504)
                      </option>
                      <option data-countryCode="HK" value="852">
                        Hong Kong (+852)
                      </option>
                      <option data-countryCode="HU" value="36">
                        Hungary (+36)
                      </option>
                      <option data-countryCode="IS" value="354">
                        Iceland (+354)
                      </option>
                      <option data-countryCode="IN" value="91">
                        India (+91)
                      </option>
                      <option data-countryCode="ID" value="62">
                        Indonesia (+62)
                      </option>
                      <option data-countryCode="IR" value="98">
                        Iran (+98)
                      </option>
                      <option data-countryCode="IQ" value="964">
                        Iraq (+964)
                      </option>
                      <option data-countryCode="IE" value="353">
                        Ireland (+353)
                      </option>
                      <option data-countryCode="IL" value="972">
                        Israel (+972)
                      </option>
                      <option data-countryCode="IT" value="39">
                        Italy (+39)
                      </option>
                      <option data-countryCode="JM" value="1876">
                        Jamaica (+1876)
                      </option>
                      <option data-countryCode="JP" value="81">
                        Japan (+81)
                      </option>
                      <option data-countryCode="JO" value="962">
                        Jordan (+962)
                      </option>
                      <option data-countryCode="KZ" value="7">
                        Kazakhstan (+7)
                      </option>
                      <option data-countryCode="KE" value="254">
                        Kenya (+254)
                      </option>
                      <option data-countryCode="KI" value="686">
                        Kiribati (+686)
                      </option>
                      <option data-countryCode="KP" value="850">
                        Korea North (+850)
                      </option>
                      <option data-countryCode="KR" value="82">
                        Korea South (+82)
                      </option>
                      <option data-countryCode="KW" value="965">
                        Kuwait (+965)
                      </option>
                      <option data-countryCode="KG" value="996">
                        Kyrgyzstan (+996)
                      </option>
                      <option data-countryCode="LA" value="856">
                        Laos (+856)
                      </option>
                      <option data-countryCode="LV" value="371">
                        Latvia (+371)
                      </option>
                      <option data-countryCode="LB" value="961">
                        Lebanon (+961)
                      </option>
                      <option data-countryCode="LS" value="266">
                        Lesotho (+266)
                      </option>
                      <option data-countryCode="LR" value="231">
                        Liberia (+231)
                      </option>
                      <option data-countryCode="LY" value="218">
                        Libya (+218)
                      </option>
                      <option data-countryCode="LI" value="417">
                        Liechtenstein (+417)
                      </option>
                      <option data-countryCode="LT" value="370">
                        Lithuania (+370)
                      </option>
                      <option data-countryCode="LU" value="352">
                        Luxembourg (+352)
                      </option>
                      <option data-countryCode="MO" value="853">
                        Macao (+853)
                      </option>
                      <option data-countryCode="MK" value="389">
                        Macedonia (+389)
                      </option>
                      <option data-countryCode="MG" value="261">
                        Madagascar (+261)
                      </option>
                      <option data-countryCode="MW" value="265">
                        Malawi (+265)
                      </option>
                      <option data-countryCode="MY" value="60">
                        Malaysia (+60)
                      </option>
                      <option data-countryCode="MV" value="960">
                        Maldives (+960)
                      </option>
                      <option data-countryCode="ML" value="223">
                        Mali (+223)
                      </option>
                      <option data-countryCode="MT" value="356">
                        Malta (+356)
                      </option>
                      <option data-countryCode="MH" value="692">
                        Marshall Islands (+692)
                      </option>
                      <option data-countryCode="MQ" value="596">
                        Martinique (+596)
                      </option>
                      <option data-countryCode="MR" value="222">
                        Mauritania (+222)
                      </option>
                      <option data-countryCode="YT" value="269">
                        Mayotte (+269)
                      </option>
                      <option data-countryCode="MX" value="52">
                        Mexico (+52)
                      </option>
                      <option data-countryCode="FM" value="691">
                        Micronesia (+691)
                      </option>
                      <option data-countryCode="MD" value="373">
                        Moldova (+373)
                      </option>
                      <option data-countryCode="MC" value="377">
                        Monaco (+377)
                      </option>
                      <option data-countryCode="MN" value="976">
                        Mongolia (+976)
                      </option>
                      <option data-countryCode="MS" value="1664">
                        Montserrat (+1664)
                      </option>
                      <option data-countryCode="MA" value="212">
                        Morocco (+212)
                      </option>
                      <option data-countryCode="MZ" value="258">
                        Mozambique (+258)
                      </option>
                      <option data-countryCode="MN" value="95">
                        Myanmar (+95)
                      </option>
                      <option data-countryCode="NA" value="264">
                        Namibia (+264)
                      </option>
                      <option data-countryCode="NR" value="674">
                        Nauru (+674)
                      </option>
                      <option data-countryCode="NP" value="977">
                        Nepal (+977)
                      </option>
                      <option data-countryCode="NL" value="31">
                        Netherlands (+31)
                      </option>
                      <option data-countryCode="NC" value="687">
                        New Caledonia (+687)
                      </option>
                      <option data-countryCode="NZ" value="64">
                        New Zealand (+64)
                      </option>
                      <option data-countryCode="NI" value="505">
                        Nicaragua (+505)
                      </option>
                      <option data-countryCode="NE" value="227">
                        Niger (+227)
                      </option>
                      <option data-countryCode="NG" value="234">
                        Nigeria (+234)
                      </option>
                      <option data-countryCode="NU" value="683">
                        Niue (+683)
                      </option>
                      <option data-countryCode="NF" value="672">
                        Norfolk Islands (+672)
                      </option>
                      <option data-countryCode="NP" value="670">
                        Northern Marianas (+670)
                      </option>
                      <option data-countryCode="NO" value="47">
                        Norway (+47)
                      </option>
                      <option data-countryCode="OM" value="968">
                        Oman (+968)
                      </option>
                      <option data-countryCode="PW" value="680">
                        Palau (+680)
                      </option>
                      <option data-countryCode="PA" value="507">
                        Panama (+507)
                      </option>
                      <option data-countryCode="PG" value="675">
                        Papua New Guinea (+675)
                      </option>
                      <option data-countryCode="PY" value="595">
                        Paraguay (+595)
                      </option>
                      <option data-countryCode="PE" value="51">
                        Peru (+51)
                      </option>
                      <option data-countryCode="PH" value="63">
                        Philippines (+63)
                      </option>
                      <option data-countryCode="PL" value="48">
                        Poland (+48)
                      </option>
                      <option data-countryCode="PT" value="351">
                        Portugal (+351)
                      </option>
                      <option data-countryCode="PR" value="1787">
                        Puerto Rico (+1787)
                      </option>
                      <option data-countryCode="QA" value="974">
                        Qatar (+974)
                      </option>
                      <option data-countryCode="RE" value="262">
                        Reunion (+262)
                      </option>
                      <option data-countryCode="RO" value="40">
                        Romania (+40)
                      </option>
                      <option data-countryCode="RU" value="7">
                        Russia (+7)
                      </option>
                      <option data-countryCode="RW" value="250">
                        Rwanda (+250)
                      </option>
                      <option data-countryCode="SM" value="378">
                        San Marino (+378)
                      </option>
                      <option data-countryCode="ST" value="239">
                        Sao Tome &amp; Principe (+239)
                      </option>
                      <option data-countryCode="SA" value="966">
                        Saudi Arabia (+966)
                      </option>
                      <option data-countryCode="SN" value="221">
                        Senegal (+221)
                      </option>
                      <option data-countryCode="CS" value="381">
                        Serbia (+381)
                      </option>
                      <option data-countryCode="SC" value="248">
                        Seychelles (+248)
                      </option>
                      <option data-countryCode="SL" value="232">
                        Sierra Leone (+232)
                      </option>
                      <option data-countryCode="SG" value="65">
                        Singapore (+65)
                      </option>
                      <option data-countryCode="SK" value="421">
                        Slovak Republic (+421)
                      </option>
                      <option data-countryCode="SI" value="386">
                        Slovenia (+386)
                      </option>
                      <option data-countryCode="SB" value="677">
                        Solomon Islands (+677)
                      </option>
                      <option data-countryCode="SO" value="252">
                        Somalia (+252)
                      </option>
                      <option data-countryCode="ZA" value="27">
                        South Africa (+27)
                      </option>
                      <option data-countryCode="ES" value="34">
                        Spain (+34)
                      </option>
                      <option data-countryCode="LK" value="94">
                        Sri Lanka (+94)
                      </option>
                      <option data-countryCode="SH" value="290">
                        St. Helena (+290)
                      </option>
                      <option data-countryCode="KN" value="1869">
                        St. Kitts (+1869)
                      </option>
                      <option data-countryCode="SC" value="1758">
                        St. Lucia (+1758)
                      </option>
                      <option data-countryCode="SD" value="249">
                        Sudan (+249)
                      </option>
                      <option data-countryCode="SR" value="597">
                        Suriname (+597)
                      </option>
                      <option data-countryCode="SZ" value="268">
                        Swaziland (+268)
                      </option>
                      <option data-countryCode="SE" value="46">
                        Sweden (+46)
                      </option>
                      <option data-countryCode="CH" value="41">
                        Switzerland (+41)
                      </option>
                      <option data-countryCode="SI" value="963">
                        Syria (+963)
                      </option>
                      <option data-countryCode="TW" value="886">
                        Taiwan (+886)
                      </option>
                      <option data-countryCode="TJ" value="7">
                        Tajikstan (+7)
                      </option>
                      <option data-countryCode="TH" value="66">
                        Thailand (+66)
                      </option>
                      <option data-countryCode="TG" value="228">
                        Togo (+228)
                      </option>
                      <option data-countryCode="TO" value="676">
                        Tonga (+676)
                      </option>
                      <option data-countryCode="TT" value="1868">
                        Trinidad &amp; Tobago (+1868)
                      </option>
                      <option data-countryCode="TN" value="216">
                        Tunisia (+216)
                      </option>
                      <option data-countryCode="TR" value="90">
                        Turkey (+90)
                      </option>
                      <option data-countryCode="TM" value="7">
                        Turkmenistan (+7)
                      </option>
                      <option data-countryCode="TM" value="993">
                        Turkmenistan (+993)
                      </option>
                      <option data-countryCode="TC" value="1649">
                        Turks &amp; Caicos Islands (+1649)
                      </option>
                      <option data-countryCode="TV" value="688">
                        Tuvalu (+688)
                      </option>
                      <option data-countryCode="UG" value="256">
                        Uganda (+256)
                      </option>
                      <option data-countryCode="GB" value="44">UK (+44)</option>
                      <option data-countryCode="UA" value="380">
                        Ukraine (+380)
                      </option>
                      <option data-countryCode="AE" value="971">
                        United Arab Emirates (+971)
                      </option>
                      <option data-countryCode="UY" value="598">
                        Uruguay (+598)
                      </option>
                      <option data-countryCode="US" value="1">USA (+1)</option>
                      <option data-countryCode="UZ" value="7">
                        Uzbekistan (+7)
                      </option>
                      <option data-countryCode="VU" value="678">
                        Vanuatu (+678)
                      </option>
                      <option data-countryCode="VA" value="379">
                        Vatican City (+379)
                      </option>
                      <option data-countryCode="VE" value="58">
                        Venezuela (+58)
                      </option>
                      <option data-countryCode="VN" value="84">
                        Vietnam (+84)
                      </option>
                      <option data-countryCode="VG" value="84">
                        Virgin Islands - British (+1284)
                      </option>
                      <option data-countryCode="VI" value="84">
                        Virgin Islands - US (+1340)
                      </option>
                      <option data-countryCode="WF" value="681">
                        Wallis &amp; Futuna (+681)
                      </option>
                      <option data-countryCode="YE" value="969">
                        Yemen (North)(+969)
                      </option>
                      <option data-countryCode="YE" value="967">
                        Yemen (South)(+967)
                      </option>
                      <option data-countryCode="ZM" value="260">
                        Zambia (+260)
                      </option>
                      <option data-countryCode="ZW" value="263">
                        Zimbabwe (+263)
                      </option>
                    </optgroup>
                  </select>
                </div>
                <div class="column">
                  <input class="child" type="number" v-model="mobile" placeholder="Mobile" />
                </div>
              </div>
              <select style="margin-bottom: 15px" v-model="reason" name="form_fields[form_free_topic]"
                id="form-field-form_free_topic" class="elementor-field-textual elementor-size-sm">
                <option value="">How can we help you?</option>
                <option value="Cyber Security">Android App Development</option>
                <option value="Web Development">iOS App Development</option>
                <option value="Mobile Development">Web App Development</option>
                <option value="Cloud Services">UI/UX Design</option>
                <option value="Cloud Services">Mobile App Consulting</option>
                <option value="Other">Other</option>
              </select>
              <textarea style="height: 80px; line-height: 1.5" name="message" v-model="message" cols="40"
                placeholder="Message" rows="3"></textarea>

              <VueRecaptcha style="position: absolute; margin-top: 90px" :sitekey="siteKey" :load-recaptcha-script="true"
                @verify="handleSuccess" @error="handleError"></VueRecaptcha>
            </div>

            <div class="tab">Select Date and Time:

              <div>
                <datepicker :value="date" name="uniquename"></datepicker>

                <h6 style="margin: 10px 0;text-align: left;">Morning Slots</h6>

                <div style="display: flex;flex-direction: row;">

                  <label class="container_check_box">10:00
                    <input style="margin-left:" type="radio" name="radio" value="10:00">
                    <span class="checkmark"></span>
                  </label>

                  <label class="container_check_box">10:30
                    <input style="margin: 0px;" type="radio" name="radio" value="10:30">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">11:00
                    <input style="margin: 0px;" type="radio" name="radio" value="11:00">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">11:30
                    <input style="margin: 0px;" type="radio" name="radio" value="11:30">
                    <span class="checkmark"></span>
                  </label>

                </div>


                <h6 style="margin: 10px 0;text-align: left;">Afternoon Slots</h6>

                <div style="display: flex;flex-direction: row;">
                  <label class="container_check_box">12:00
                    <input style="margin-left:" type="radio" name="radio" value="12:00">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">12:30
                    <input style="margin: 0px;" type="radio" name="radio" value="12:30">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">01:00
                    <input style="margin: 0px;" type="radio" name="radio" value="01:00">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">01:30
                    <input style="margin: 0px;" type="radio" name="radio" value="01:30">
                    <span class="checkmark"></span>
                  </label>

                </div>


                <h6 style="margin: 10px 0;text-align: left;">Evening Slots</h6>

                <div style="display: flex;flex-direction: row;">
                  <label class="container_check_box">04:00
                    <input style="margin-left:" type="radio" name="radio" value="04:00">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">04:30
                    <input style="margin: 0px;" type="radio" name="radio" value="04:30">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">05:00
                    <input style="margin: 0px;" type="radio" name="radio" value="05:00">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container_check_box">05:30
                    <input style="margin: 0px;" type="radio" name="radio" value="05:30">
                    <span class="checkmark"></span>
                  </label>

                </div>

              </div>


            </div>

            <div class="tab">
              <div style="font-size: 11px; line-height: 1.2; text-align: start">
                Short description about your product that you are looking to
                build with us ?
              </div>
              <textarea style="height: 40px; line-height: 1.5" name="description" v-model="description" cols="40"
                placeholder="" rows="3"></textarea>
              <div style="font-size: 11px; line-height: 1.2; text-align: start">
                What is your largest pain point you have without this product ?
              </div>
              <textarea style="height: 40px; line-height: 1.5" name="painPoint" v-model="painPoint" cols="40"
                placeholder="" rows="3"></textarea>
              <div style="font-size: 11px; line-height: 1.2; text-align: start">
                How does having this product impact your business ?
              </div>
              <textarea style="height: 40px; line-height: 1.5" name="impact" v-model="impact" cols="40" placeholder=""
                rows="3"></textarea>
              <div style="font-size: 11px; line-height: 1.2; text-align: start">
                Is there anything you’d like to add?
              </div>
              <textarea style="height: 40px; line-height: 1.5" name="remarks" v-model="remarks" cols="40" placeholder=""
                rows="3"></textarea>
            </div>

            <!-- <button class="button"
                        style="background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%);margin-top: 60px; height: 40px; padding: 0 30px;"
                        @click="sendMessage">Proceed</button> -->

            <button class=" btn btn-primary text-uppercase contact-form-button prev" type="button" id="prevBtn"
              @click="handlePrevButton(-1)">
              Previous
            </button>
            <button class="btn btn-primary text-uppercase contact-form-button next" type="button" id="nextBtn"
              @click="handleNextButton(1)">
              Next
            </button>



            <!-- <div style="overflow:auto;">
                        <div style="float:right;">
                            <button type="button" id="prevBtn" onclick="nextPrev(-1)">Previous</button>
                            <button type="button" id="nextBtn" onclick="nextPrev(1)">Next</button>
                        </div>
                    </div> -->

            <!-- Circles which indicates the steps of the form: -->
            <div style="text-align: center; margin-top: 10px">
              <span class="step"></span>
              <span class="step"></span>
              <span class="step"></span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="deviceType != 'mobile'" class="box1" style="
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          margin: 0px;
        ">
        <div style="
            height: 180px;
            width: 100%;
            background: #231f20;
            position: absolute;
          ">
          <h2 style="
              font-weight: bold;
              position: absolute;
              bottom: 5px;
              left: 20px;
              color: #b7cee3;
            ">
            Partner with Us for <br />
            Comprehensive IT
          </h2>
        </div>

        <div style="
            height: 30rem;
            width: 100%;
            background: linear-gradient(
              100.16deg,
              #b7cee3 3.63%,
              #a5c2dc 3.63%,
              #b7cee3 3.63%,
              #b7cee3 8.02%,
              #96b8d5 32.08%,
              #588ebd 99.6%,
              #cfdfec 104.99%
            );
          ">
          <div style="margin-top: 180px; padding: 20px">
            <h6 style="color: #001155; font-size: 20px">
              We’re happy to answer any questions you may have and<br />help you
              determine which of our services best fit your needs.
            </h6>

            <div style="margin-top: 20px">
              <a href="tel:+916379232460">
                <span class="elementor-button-content-wrapper">
                  <span class="elementor-button-text"><span style="color: #001155; font-weight: 400; font-size: 20px">Call
                      us at: +91 6379232460</span></span>
                </span>
              </a>
            </div>
            <h6 class="h1" style="
                font-size: 24px;
                color: #001155;
                font-weight: 700;
                margin-top: 3rem;
              ">
              Your benefits:
            </h6>

            <div class="d-flex justify-content-start">
              <div class="" style="color: #001155">
                <lu style="font-size: 13px;" class="list-unstyled">
                  <li>
                    <img src="../assets/icons/list_item_icon.svg" style="max-width: 15px; min-width: 15px" />
                    Client-oriented
                  </li>
                  <li>
                    <img src="../assets/icons/list_item_icon.svg" style="max-width: 15px; min-width: 15px" />
                    Independent
                  </li>
                  <li>
                    <img src="../assets/icons/list_item_icon.svg" style="max-width: 15px; min-width: 15px" />
                    Transparent
                  </li>
                </lu>
              </div>
              <div style="padding-left: 4rem; color: #001155">
                <lu style="font-size: 13px;" class="list-unstyled">
                  <li>
                    <img src="../assets/icons/list_item_icon.svg" style="max-width: 15px; min-width: 15px" />
                    Problem-solving
                  </li>
                  <li>
                    <img src="../assets/icons/list_item_icon.svg" style="max-width: 15px; min-width: 15px" />
                    Results-driven
                  </li>
                  <li>
                    <img src="../assets/icons/list_item_icon.svg" style="max-width: 15px; min-width: 15px" />
                    Competent
                  </li>
                </lu>
              </div>
            </div>

            <h5 style="margin-top: 2rem; font-weight: 700; color: #001155">
              What happens next?
            </h5>
            <div class="d-flex align-items-end" style="margin-top: 1rem">
              <div style="display: flex; flex-direction: row; margin-right: 2rem">
                <div style="display: flex; flex-direction: row; margin-right: 2rem">
                  <h6 style="font-weight: 700; color: #001155; font-size: 24px">
                    1
                  </h6>
                  <div style="font-size: 13px; color: #001155; margin: 0px 10px">
                    <p style="
                        margin-bottom: 0;
                        color: #001155;
                        font-weight: 500;
                        line-height: 1rem;
                      ">
                      We Schedule a call<br />at your convenience&nbsp;
                    </p>
                  </div>

                  <div class="elementor-icon">
                    <img src="../assets/icons/arrow_forward_ios.svg" style="max-width: 20px; min-width: 20px" />
                  </div>
                </div>

                <div style="display: flex; flex-direction: row; margin-right: 2rem">
                  <h6 style="font-weight: 700; color: #001155; font-size: 24px">
                    2
                  </h6>
                  <div style="font-size: 13px; color: #001155; margin: 0px 10px">
                    <p style="
                        margin-bottom: 0;
                        color: #001155;
                        font-weight: 500;
                        line-height: 1rem;
                      ">
                      We do a discovery and<br />consulting meeting&nbsp;
                    </p>
                  </div>

                  <div class="elementor-icon">
                    <img src="../assets/icons/arrow_forward_ios.svg" style="max-width: 20px; min-width: 20px" />
                  </div>
                </div>

                <div style="display: flex; flex-direction: row">
                  <h6 style="font-weight: 700; color: #001155; font-size: 24px">
                    3
                  </h6>
                  <div style="font-size: 13px; color: #001155; margin: 0px 10px">
                    <p style="
                        margin-bottom: 0;
                        color: #001155;
                        font-weight: 500;
                        line-height: 1rem;
                      ">
                      We prepare a<br />proposal&nbsp;
                    </p>
                  </div>

                  <div class="elementor-icon">
                    <!--  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="20"
                      viewBox="0 0 12 61"
                    >
                      <path
                        d="m.37.6.95-.3 10.21 30.62L1.31 60.57l-.95-.33 10.11-29.32z"
                        fill=""
                        fill-rule="nonzero"
                        fill-opacity=""
                      ></path>
                    </svg> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <transition name="toast">
    <Toast v-bind:message="toastMessage" v-if="showToast" />
  </transition>
  <transition name="toast">
    <Alert v-if="showAlert" />
  </transition>
</template>

<script>
/* eslint-disable */
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebase";
import Toast from "../components/Toast.vue";
import Alert from "../components/Alert.vue";
import Datepicker from "vuejs3-datepicker";

import { ref } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import { computed, defineComponent } from "vue";

export default {
  name: "ContactForm",

  data() {
    return {
      errors: [],

      name: "",
      mobile: "",
      country: "91",
      email: "",
      reason: "",
      message: "",

      description: "",
      painPoint: "",
      impact: "",
      remarks: "",

      toastMessage: "",
      deviceType: window.innerWidth <= 900 ? "mobile" : "desktop",
      currentTab: 0,

      date: new Date().toLocaleString(),
      time: "",
      disabledDates: {
        to: new Date(2023, 11, 5), // Disable all dates up to specific date
        from: new Date(2016, 0, 26), // Disable all dates after specific date

        dates: [
          // Disable an array of dates
          new Date(2016, 9, 16),
          new Date(2016, 9, 17),
          new Date(2016, 9, 18),
        ],
        preventDisableDateSelection: true,
      },
    };
  },

  components: { Toast, Alert, VueRecaptcha, Datepicker },
  setup() {
    //captcha

    const siteKey = computed(() => {
      return "6LftgXcjAAAAAE4n-FXFzevp25leNiu_JTPHgT2E";
    });

    const handleError = () => {
      disableButton.value = true;
    };

    const handleSuccess = (response) => {
      console.log("print", disableButton.value);
      disableButton.value = false;
    };

    const showToast = ref(false);
    const showAlert = ref(false);
    const disableButton = ref(true);

    const triggerToast = () => {
      showToast.value = true;
      setTimeout(() => (showToast.value = false), 3000);
    };
    const triggerAlert = () => {
      showAlert.value = true;
      setTimeout(() => (showAlert.value = false), 5000);
    };

    return {
      showToast,
      triggerToast,
      showAlert,
      triggerAlert,
      handleSuccess,
      handleError,
      siteKey,
      disableButton,
    };
  },

  mounted() {
    this.showTab(this.currentTab);
  },

  methods: {
    onValidationClose() {
      this.errors = [];
    },

    async sendMessage() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.name == "") {
        this.toastMessage = "Name cannot be empty";
        this.triggerToast();
      } else if (!this.email.match(validRegex)) {
        this.toastMessage = "Enter a valid email address";
        this.triggerToast();
      } else if (this.message == "") {
        this.toastMessage = "Message cannot be empty";
        this.triggerToast();
      }
      // else if (this.disableButton == true) {
      //     this.toastMessage = 'Please complete the reCAPTCHA'
      //     this.triggerToast()
      // }
      else {
        try {
        const colRef = collection(db, "messages");
        const dataObj = {
          name: this.name,
          mobile: this.mobile,
          country: this.country,
          email: this.email,
          reason: this.reason,
          message: this.message,
          description: this.description,
          painPoint: this.painPoint,
          impact: this.impact,
          remarks: this.remarks,
          meetingDate: this.date,
          meetingTime: this.time,
          timeStamp: new Date().toLocaleString(),
        };
        const docRef = await addDoc(colRef, dataObj);
        this.name = "";
        this.mobile = "";
        this.country = "91";
        this.email = "";
        this.message = "";
        this.description = "";
        this.painPoint = "";
        this.impact = "";
        this.remarks = "";
        this.triggerAlert();
         } catch (error) {
      // Handle Firestore errors
      console.error("Error adding document: ", error);
      // this.toastMessage = "An error occurred while sending your message. Please try again.";
      // this.triggerToast();
    }
      }
    },

    // checkForm: function () {
    //   var validRegex =
    //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    //   if (this.name && this.mobile && this.country && this.message && this.reason) {
    //     return true;
    //   }

    //   this.errors = [];

    //   if (!this.name) {
    //     this.errors.push('Name required.');
    //   }
    //   if (!this.mobile) {
    //     this.errors.push('Mobile number required.');
    //   }
    //   if (!this.email.match(validRegex)) {
    //     this.errors.push('Enter a valid email address')
    //   }
    //   if (!this.reason) {
    //     this.errors.push('Reason is required');
    //   }
    //   if (!this.message) {
    //     this.errors.push('Message is required');
    //   }
    //   // e.preventDefault();
    //   this.handleNextButton(1)

    // },

    showTab(n) {
      // This function will display the specified tab of the form...
      var x = document.getElementsByClassName("tab");
      x[n].style.display = "block";
      //... and fix the Previous/Next buttons:
      if (n == 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "inline";
      }
      if (n == 2) {
        document.getElementById("nextBtn").innerHTML = "Submit";
      } else {
        document.getElementById("nextBtn").innerHTML = "Proceed";
      }
      //... and run a function that will display the correct step indicator:
      this.fixStepIndicator(n);
    },

    validateForm() {
      // This function deals with validation of the form fields
      var x,
        y,
        i,
        valid = true;
      x = document.getElementsByClassName("tab");
      y = x[currentTab].getElementsByTagName("input");
      // A loop that checks every input field in the current tab:
      for (i = 0; i < y.length; i++) {
        // If a field is empty...
        if (y[i].value == "") {
          // add an "invalid" class to the field:
          y[i].className += " invalid";
          // and set the current valid status to false
          valid = false;
        }
      }
      // If the valid status is true, mark the step as finished and valid:
      // if (valid) {
      //   document.getElementsByClassName("step")[currentTab].className += " finish";
      // }
      return valid; // return the valid status
    },

    handleNextButton(n) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var radios = document.getElementsByName("radio");

      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          this.time = radios[i].value;
          break;
        }
      }
      console.log("clicked on n", n);
      // if (n == 1 && !validateForm()) return false;

      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.mobile) {
        this.errors.push("Mobile number required.");
      }
      if (!this.email.match(validRegex)) {
        this.errors.push("Enter a valid email address");
      }
      if (!this.reason) {
        this.errors.push("Reason is required");
      }
      if (!this.message) {
        this.errors.push("Message is required");
      }
      if (this.disableButton == true) {
        this.errors.push("Please complete the reCAPTCHA");
      }

      if (this.errors.length == 0) {
        if (this.currentTab == 2) {
          this.sendMessage();
        } else {
          var x = document.getElementsByClassName("tab");
          x[this.currentTab].style.display = "none";
          this.currentTab = this.currentTab + n;
          this.showTab(this.currentTab);
          console.log("currentBtn", this.currentTab);
        }
      }
    },

    handlePrevButton(n) {
      var x = document.getElementsByClassName("tab");
      x[this.currentTab].style.display = "none";
      this.currentTab = this.currentTab + n;
      this.showTab(this.currentTab);

      l̥;
    },

    fixStepIndicator(n) {
      // This function removes the "active" class of all steps...
      var i,
        x = document.getElementsByClassName("step");
      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
      }
      //... and adds the "active" class on the current step:
      x[n].className += " active";
    },
  },
};
</script>

<style>
.tab {
  display: none;
}

.cover {
  position: absolute;
  background-color: #efeeee;
  /* border: #040404 1px solid; */
  /* height: 100%; */
  width: 100%;
  display: block;
  z-index: 999;
}

.contact-form-button {
  /* border: none;
  padding: 0px 20px;
  font-size: 0.9rem;
  height: 40px; */
  margin-top: 80px;
  /* border-radius: 5px;
  cursor: pointer; */
}

.contact-form-button.next {
  background-color: var(--primary-color);
  color: #ffffff;
}

.contact-form-button.prev {
  background-color: #e9e9e9;
  color: var(--primary-color);
  margin-right: 10px;
}

button:hover {
  opacity: 0.8;
}

.vuejs3-datepicker__value {
  min-width: 200px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-self: left;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
  border: 1px solid;
}

/* #prevBtn {
  background-color: #E9E9E9;
  color: var(--primary-color);
  padding-right: 10px;
} */

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #04aa6d;
}

.elementor-section .elementor-container {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.elementor-icon {
  margin: 0 10px;
}

.contact_form_container {
  width: 100%;
  height: 560px;
  position: relative;
  background: #231f20;
}

/* .container_check_box {
  display: block;
  position: relative;
  display: flex;
  border: 1px solid black;
  flex-direction: row;
  margin-left: 10px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
} */

/* The container */
.container_check_box {
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 5px; */
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container_check_box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_check_box:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_check_box input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_check_box input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_check_box .checkmark:after {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}

/* check box style ends here */

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px;
  /* for demo purpose  */
}

.stack-top {
  z-index: 9;
  margin: 20px;
  /* for demo purpose  */
}

.column1 {
  float: left;
  width: 30%;
}

.column {
  float: left;
  width: 67%;
  margin-left: 3%;
}

.row1:after {
  content: "";
  display: table;
  clear: both;
}

.content-center {
  text-align: center;
  margin: auto;
  justify-content: center;
  box-shadow: 0px 0px 100px 0px rgb(0 0 0 / 5%);
  border-radius: 10px;
}

input,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  width: 100%;
  float: left;
  padding: 0 15px;
  height: 35px;
  margin-bottom: 20px;
  line-height: 48px;
  background: #ffffff;
  border: 1px solid #e0e0e1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #040404;
  font-size: 12px;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
}

select {
  border-radius: 4px;
  border: 1px solid #e0e0e1;
  height: 35px;
  font-size: 12px;
  color: black;
}

/* enter transitions */
.toast-enter-active {
  animation: wobble 0.5s ease;
}

/* leave transitions */
.toast-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}

.toast-leave-active {
  transition: all 0.3s ease;
}

@keyframes wobble {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
  }

  60% {
    transform: translateX(8px);
    opacity: 1;
  }

  70% {
    transform: translateX(-8px);
    opacity: 1;
  }

  80% {
    transform: translateX(4px);
    opacity: 1;
  }

  90% {
    transform: translateX(-4px);
    opacity: 1;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
