<template>
  <div class="main-content overflow-hidden">
    <section class="pb-0" id="iq-about">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="title-box text-left">
              <img
                class="iq-works-imgs"
                src="https://thinkalternate.com/images/what_is_thinkalt.jpg"
                alt="drive01"
              />
              <h2>What is ThinkAlt ?</h2>
              <p>
                ThinkAlt is a brand that caters to serve Ideas-To-Product
                solution for Start-ups. Helping you achieve your technology
                Goals is our business. We are Agile, always-pivoting and help
                you reinvent your product.
              </p>
              <!-- <a class="button org-btn mt-5" href="#">Read More</a> -->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6 squre-effect-top">
                <div
                  class="squre-effect wow fadeInUp text-left"
                  data-wow-delay="0.4s"
                >
                  <div class="icon-box">
                    <div class="img-effect mb-3">
                      <i
                        aria-hidden="true"
                        class="flaticon flaticon-paper-plane blue-color"
                      ></i>
                    </div>
                    <h5 class="mb-2">Curated Talent</h5>
                    <p v-if="showMoreT">
                      Our developers treat your product like their own...
                    </p>
                    <p v-else class="mb-0">
                      Our developers treat your product like their own baby
                      product to make sure the best results are achieved.
                    </p>
                    <button
                      class="button-read-more"
                      @click="showMoreT = !showMoreT"
                    >
                      {{ showMoreT ? "Read more" : "Read less" }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="squre-effect wow fadeInUp text-left"
                  data-wow-delay="0.6s"
                >
                  <div class="icon-box">
                    <div class="img-effect mb-3">
                      <i
                        aria-hidden="true"
                        class="flaticon flaticon-goal orange-color"
                      ></i>
                    </div>
                    <h5 class="mb-2">Clean Coding</h5>
                    <p v-if="showMoreC">
                      With clean coding practices and better...
                    </p>
                    <p v-else class="mb-0">
                      With clean coding practices and better technology choices
                      we make your product not just beautiful from outside but
                      also from inside.
                    </p>
                    <button
                      class="button-read-more"
                      @click="showMoreC = !showMoreC"
                    >
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 squre-effect-bottom">
                <div
                  class="squre-effect wow fadeInUp text-left"
                  data-wow-delay="0.4s"
                >
                  <div class="icon-box">
                    <div class="img-effect mb-3">
                      <i
                        aria-hidden="true"
                        class="flaticon flaticon-cloud green-color"
                      ></i>
                    </div>
                    <h5 class="mb-2">Business Assistance</h5>
                    <p v-if="showMoreA">
                      We know you’re not looking to do the same...
                    </p>
                    <p v-else class="mb-0">
                      We know you’re not looking to do the same thing as
                      everyone else and that’s why we help in reinventing your
                      products with your ideas and our experience to make it
                      kick-ass awesome!
                    </p>
                    <button
                      class="button-read-more"
                      @click="showMoreA = !showMoreA"
                    >
                      Read more
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="squre-effect wow fadeInUp text-left"
                  data-wow-delay="0.6s"
                >
                  <div class="icon-box">
                    <div class="img-effect mb-3">
                      <i
                        aria-hidden="true"
                        class="flaticon flaticon-investment yellow-color"
                      ></i>
                    </div>
                    <h5 class="mb-2">Clean Slate Products</h5>
                    <p v-if="showMoreP">
                      We start with a clean slate to create a simple...
                    </p>
                    <p v-else class="mb-0">
                      We start with a clean slate to create a simple and
                      efficient software product.
                    </p>
                    <button
                      class="button-read-more"
                      @click="showMoreP = !showMoreP"
                    >
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Expertise -->
    <section class="pb-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="title-box text-left">
              <h2>Expertise</h2>
              <p>
                Our expertise involve Native Android and iOS Mobile Apps,
                ReactNative Apps, Flutter Apps, Web Apps & Progressive Web Apps
                product development. We also create beautiful eye-candy admin
                dashboards with statistics and analytics to always help you stay
                ahead in your business
              </p>
              <!-- <a class="button org-btn mt-5" href="#">Read More</a> -->
            </div>
          </div>
          <div class="col-lg-6 mb-lg-0 mb-5">
            <img
              src="https://thinkalternate.com/images/thinkalt_expertise.png"
              class="img-fluid"
              alt="blogimage1"
            />
          </div>
        </div>
      </div>
    </section>
    <!--  -->
    <section class="pb-0">
      <div class="container">
        <div class="title-box text-left">
          <h2>Why ThinkAlt ?</h2>
          <p>Zero Maintenance Cost. Zero Billing for Bug-fixes, Forever!</p>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="media service-box">
              <i aria-hidden="true" class="ion-ios-pie-outline"></i>
              <div class="media-body" style="text-align: justify">
                <h5 class="mt-0">Design</h5>
                Our state of the art design team will make sure your brand logos
                and UI/UX is done right.
              </div>
            </div>
            <div class="media service-box">
              <i aria-hidden="true" class="ion-ios-grid-view-outline"></i>
              <div class="media-body" style="text-align: justify">
                <h5 class="mt-0">Code</h5>
                Clean Coding, Legible and indented code with better readabliity
                makes it easier for all of us.
              </div>
            </div>
            <div class="media service-box">
              <i aria-hidden="true" class="ion-ios-monitor-outline"></i>
              <div class="media-body" style="text-align: justify">
                <h5 class="mt-0">Features & Functionality</h5>
                We assist you in choosing the right set for functionalities for
                both your MVP and the final product.
              </div>
            </div>
            <div class="media service-box">
              <i aria-hidden="true" class="ion-ios-gear-outline"></i>
              <div class="media-body" style="text-align: justify">
                <h5 class="mt-0">Execute</h5>
                We follow Agile software development methodology for building
                your product. This makes sure your product is planned well and
                delivered on time.
              </div>
            </div>
            <!-- <a class="button org-btn mt-5" href="#">Read More</a> -->
          </div>
          <div class="col-lg-6 mb-lg-0 mb-5">
            <img
              src="https://thinkalternate.com/images/why_thinkalternate.png"
              class="img-fluid"
              alt="blogimage1"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Slider from '../components/REVSlider.vue'
export default {
  name: "BodyContent",
  // components(){
  //     Slider
  // },
  data() {
    return {
      showMoreT: true,
      showMoreC: true,
      showMoreA: true,
      showMoreP: true,
    };
  },
  methods: {},
};
</script>
