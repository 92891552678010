import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore} from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCuJ1IlvnbvuTc2VLj67sUcO_UX0DrMZ_E",
    authDomain: "that-72d39.firebaseapp.com",
    projectId: "that-72d39",
    storageBucket: "that-72d39.appspot.com",
    messagingSenderId: "950375592332",
    appId: "1:950375592332:web:748bc0bf5fe34215dc66a9",
    measurementId: "G-LRDD9BYDBD"
  };
  
  // Initialize Firebase
  /* eslint-disable */
  const firebaseapp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseapp);
  const db = getFirestore(firebaseapp)

  

  export{
    db
  }