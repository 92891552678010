<template>
  <p class="rs-p-wp-fix"></p>  
  <rs-module-wrap id="rev_slider_3_1_wrapper" data-alias="sofbox-13" data-source="gallery"
    style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
    <rs-module id="rev_slider_3_1" style="display:none;" data-version="6.1.3">
      <rs-slides>
        <rs-slide data-key="rs-5" id="iq-home" data-title="Slide"
          data-thumb="../assets/frontend/revslider/assets/startup/46.png" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
          <img src='../assets/frontend/revslider/assets/startup/transparent.png' title="46" width="1600" height="1000"
            class="rev-slidebg" data-no-retina>

          <!--
		-->
          <rs-layer id="slider-3-slide-5-layer-0" data-type="image" data-rsp_ch="on"
            data-xy="xo:652px,556px,200px,114px;yo:136px,116px,299px,276px;"
            data-text="w:normal;s:20,17,12,7;l:0,21,15,9;"
            data-dim="w:567px,483px,366px,239px;h:468px,399px,303px,197px;"
            data-frame_1="e:Power4.easeInOut;st:340;sp:1000;" data-frame_999="o:0;st:w;sR:7660;"
            style="z-index:9;font-family:Roboto;"><img src="../assets/frontend/revslider/assets/startup/img-2.png"
              alt="img" width="637" height="526" data-no-retina>
          </rs-layer>
          <!--
		-->
          <rs-layer id="slider-3-slide-5-layer-1" class="now-font" data-type="text" data-color="#1e1e1e"
            data-rsp_ch="on" data-xy="x:l,l,c,c;xo:50px,42px,0,0;y:t,t,m,m;yo:230px,196px,-240px,-188px;"
            data-text="w:normal;s:46,39,29,30;l:70,59,44,40;a:left,left,center,center;" data-frame_0="y:100%;"
            data-frame_0_mask="u:t;" data-frame_1="sp:1200;" data-frame_1_mask="u:t;" data-frame_999="o:0;st:w;sR:7800;"
            style="z-index:10;font-family:var(--title-font-family);">
            Quality is the best <br>business plan.
          </rs-layer> 
          <!--
		-->
          <rs-layer id="slider-3-slide-5-layer-2" data-type="text" data-color="#8e989f" data-rsp_ch="on"
            data-xy="x:l,l,c,c;xo:49px,41px,0,0;yo:385px,328px,146px,120px;"
            data-text="w:normal;s:16,13,16,14;l:30,25,26,26;a:left,left,center,center;" data-frame_0="y:100%;"
            data-frame_0_mask="u:t;" data-frame_1="st:620;sp:1200;sR:620;" data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:7180;" style="z-index:11;font-family:var(--body-font-family);">There are many
            variations of passages of
            Lorem Ipsum<br> available, but the majority have suffered alterat.
          </rs-layer>
          <!--
		-->
          <rs-layer id="slider-3-slide-5-layer-3" class="rev-btn" data-type="button" data-rsp_ch="on"
            data-xy="x:l,l,c,c;xo:48px,40px,0,-7px;yo:482px,411px,215px,203px;"
            data-text="w:normal;s:14,11,16,16;l:24,20,15,26;" data-dim="minh:0px,none,none,none;"
            data-padding="t:10,9,10,10;r:30,26,30,30;b:10,9,10,10;l:30,26,30,30;" data-border="bor:3px,3px,3px,3px;"
            data-frame_0="y:100%;" data-frame_0_mask="u:t;" data-frame_1="st:1030;sp:1200;sR:1030;"
            data-frame_1_mask="u:t;" data-frame_999="o:0;st:w;sR:6770;"
            data-frame_hover="bgc:#007aff;bor:3px,3px,3px,3px;sp:100;bri:120%;"
            style="z-index:12;background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%);font-family:var(--body-font-family);">
            Read More
          </rs-layer>
        </rs-slide>   
        <!--
		-->
        <rs-slide data-key="rs-7" data-title="Slide" data-thumb="../assets/frontend/revslider/assets/startup/46.png"
          data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
          <img src="../assets/frontend/revslider/assets/startup/transparent.png" title="46" width="1600" height="1000" class="rev-slidebg" data-no-retina>
          <!--
		-->
          <rs-layer id="slider-3-slide-7-layer-0" data-type="image" data-rsp_ch="on"
            data-xy="xo:652px,556px,200px,114px;yo:136px,116px,299px,276px;"
            data-text="w:normal;s:20,17,12,7;l:0,21,15,9;"
            data-dim="w:567px,483px,366px,239px;h:468px,399px,303px,197px;"
            data-frame_1="e:Power4.easeInOut;st:340;sp:1000;" data-frame_999="o:0;st:w;sR:7660;"
            style="z-index:9;font-family:Roboto;"><img src="../assets/frontend/revslider/assets/startup/img-1.png"
              alt="img" width="637" height="526" data-no-retina>
          </rs-layer>
          <!--
		-->
          <rs-layer id="slider-3-slide-7-layer-1" class="now-font" data-type="text" data-color="#1e1e1e"
            data-rsp_ch="on" data-xy="x:l,l,c,c;xo:50px,42px,0,0;y:t,t,m,m;yo:230px,196px,-240px,-188px;"
            data-text="w:normal;s:46,39,29,30;l:70,59,44,40;a:left,left,center,center;" data-frame_0="y:100%;"
            data-frame_0_mask="u:t;" data-frame_1="sp:1200;" data-frame_1_mask="u:t;" data-frame_999="o:0;st:w;sR:7800;"
            style="z-index:10;font-family:Poppins;">Quality is the best
            <br>business plan.
          </rs-layer>
          <!--
		-->
          <rs-layer id="slider-3-slide-7-layer-2" data-type="text" data-color="#8e989f" data-rsp_ch="on"
            data-xy="x:l,l,c,c;xo:49px,41px,0,0;yo:385px,328px,146px,120px;"
            data-text="w:normal;s:16,13,16,14;l:30,25,26,26;a:left,left,center,center;" data-frame_0="y:100%;"
            data-frame_0_mask="u:t;" data-frame_1="st:620;sp:1200;sR:620;" data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:7180;" style="z-index:11;font-family: var(--body-font-family);">There are many
            variations of passages
            of Lorem Ipsum<br> available, but the majority have suffered alterat.
          </rs-layer>
          <!--
		-->
          <rs-layer id="slider-3-slide-7-layer-3" class="rev-btn" data-type="button" data-rsp_ch="on"
            data-xy="x:l,l,c,c;xo:48px,40px,0,-7px;yo:482px,411px,215px,203px;"
            data-text="w:normal;s:14,11,16,16;l:24,20,15,26;" data-dim="minh:0px,none,none,none;"
            data-padding="t:10,9,10,10;r:30,26,30,30;b:10,9,10,10;l:30,26,30,30;" data-border="bor:3px,3px,3px,3px;"
            data-frame_0="y:100%;" data-frame_0_mask="u:t;" data-frame_1="st:1030;sp:1200;sR:1030;"
            data-frame_1_mask="u:t;" data-frame_999="o:0;st:w;sR:6770;"
            data-frame_hover="bgc:#007aff;bor:3px,3px,3px,3px;sp:100;bri:120%;"
            style="z-index:12;background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%);font-family: var(--body-font-family);">
            Read More
          </rs-layer>
          <!--
		-->
        </rs-slide>
      </rs-slides>
      <!-- <rs-static-layers> -->
        <!--
		-->
      <!-- </rs-static-layers> -->
      <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
    </rs-module>
  </rs-module-wrap>
</template>

<script>
export default {
  name: 'REVSlider',
}
</script>

<style>
.co {
  background-color: #3b91e7;
}
</style>