<template>
  <section class="pb-0" id="services">
    <div class="container p-5 services-container">
      <div class="row">
        <div class="col-lg-8 col-12">
          <div class="small_section">what we do</div>
          <div class="h1 services-heading">
            The only app developers making your wildest app dreams a reality.
          </div>
          <p class="services-paragraph mb-0">
            We'll take over the tricky tech stuff. And build you a reliable,
            functional, and user-friendly app. So you can serve your customers,
            grow, streamline your business. Without the stress of working with
            inconsistent freelancers. Without expensive in-house teams draining
            your funds. Without the limitations of no-code platforms. Without
            working with very expensive and slow large corporate teams.
          </p>
        </div>

        <div class="col-lg-4 col-12 d-flex align-items-end justify-content-center">
          <img
            src="../assets/images/up_arrrow_image.png"
            class="fixed-bottom-right img-fluid rounded-circle"
            alt="Arrow Image"
          />
        </div>
      </div>
    </div>

    <div class="container service-icons">
      <div class="row">
        <div class="col-md-3 col-6 mb-20 service-item">
          <router-link to="/mobile-app-development">
            <div class="icon-container mobile_app_development_hover">
              <img
                src="../assets/home_page_icons/mobile_dev_icon.png"
                class="service-icon img-fluid"
                alt="Mobile App Development"
              />
            </div>
            <div class="service-title">Mobile App<br />Development</div>
          </router-link>
        </div>

        <div class="col-md-3 col-6 mb-20 service-item">
          <router-link to="/web-app-development">
            <div class="icon-container web_app_development_hover">
              <img
                src="../assets/home_page_icons/web_dev_icon.png"
                class="service-icon img-fluid"
                alt="Web App Development"
              />
            </div>
             <div class="service-title">Web App<br />Development</div>
          </router-link>
         
        </div>

        <div class="col-md-3 col-6 mb-20 service-item">
          <router-link to="/product-design">
            <div class="icon-container ui_ux_design_hover">
              <img
                src="../assets/home_page_icons/ui_ux_icon.png"
                class="service-icon img-fluid"
                alt="Product Design"
              />
            </div>
             <div class="service-title">Product<br />Design</div>
          </router-link>
        </div>

        <div class="col-md-3 col-6 mb-20 service-item">
          <router-link to="/software-consulting">
            <div class="icon-container mobile_app_consulting_hover">
              <img
                src="../assets/home_page_icons/mobile_consulting_icon.png"
                class="service-icon img-fluid"
                alt="Software Consulting"
              />
            </div>
             <div class="service-title">Software<br />Consulting</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="d-flex justify-content-center">
        <a class="view-all-services mb-5">
          <router-link to="solutions"><u>VIEW ALL SERVICES</u></router-link>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { ref } from "vue";
export default {
  name: "OurServices",
  props: ["servicesList"],
  methods: {
    onClick: function (index) {
      this.servicesList[index].showMore = !this.servicesList[index].showMore;
    },
    data() {
      return {
        deviceType: window.innerWidth <= 700 ? "mobile" : "desktop",
      };
    },
  },
};
</script>

<style>
/* Base container with background and rounded corners */
.services-container {
  background: linear-gradient(145deg, #1f1f1f, #2c2c2c);
  color: #ffffff;
  padding: 4rem 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Service icons grid styling */
.service-icons {
  margin-bottom: 5rem;
  margin-top: 3rem;
}

/* General styles for headings and paragraph */
.h1.services-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #f0f0f0;
  animation: fadeInUp 0.8s ease-out;
}

.services-paragraph {
  margin-top: 2rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #cccccc;
}

/* Image adjustments */
.fixed-bottom-right {
  max-width: 70%;
  height: auto;
  transition: transform 0.3s ease;
  margin-top: 1rem;
}

.fixed-bottom-right:hover {
  transform: scale(1.05);
}

/* Icons container and titles */
.service-icon {
  width: 80px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.service-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 1rem;
  text-align: center;
  margin-top: 0.8rem;
}

/* Hover effects */
.icon-container {
  padding: 12px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
   text-align: center; /* Center the content */
}

.icon-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

/* Adjustments for Mobile Devices */
@media (max-width: 768px) {
  .services-container {
    padding: 2rem 1rem;
  }

  .h1.services-heading {
    font-size: 1.8rem;
  }

  .services-paragraph {
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .fixed-bottom-right {
    max-width: 60%;
  }

  .service-icon {
    width: 70px;
  }

  .service-title {
    font-size: 14px;
  }

  .view-all-services {
    font-size: 14px;
  }

  .icon-container {
    padding: 8px;
  }
}

/* Animation Effects */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* View all services link */
.view-all-services {
  color: #1ea7fd;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.3s ease;
}

.view-all-services:hover {
  color: #147dbf;
}
</style>
