<template>
  <section>
    <div class="hero-section">
      <h1 class="hero-title">Latest Blog Insights</h1>
      <p class="hero-subtitle">
        Explore our collection of expert blogs, industry news, and innovative
        stories to stay updated with the latest trends and insights.
      </p>
    </div>

    <div class="blog-cards-section container">
      <div class="blog-grid">
        <div
          class="blog-card"
          v-on:click="handleOnBlogClick(blog.id)"
          v-for="(blog, index) in posts"
          :key="index"
        >
          <!-- Blog Image and Category -->
          <div
            class="blog-image"
            :style="{ backgroundImage: `url(${blog.data().image})` }"
          >
            <span class="blog-category">{{ blog.data().tag }}</span>
          </div>

          <!-- Blog Details -->
          <div class="blog-details">
            <!-- Blog Date and Time to Read -->
            <div class="blog-meta">
              <span>{{ getFormattedData(blog.data().timestamp) }}</span>
              <span>{{ blog.data().readTime }} read</span>
            </div>

            <!-- Blog Title and Description -->
            <h2 class="blog-title">{{ blog.data().title }}</h2>
            <p class="blog-description">{{ blog.data().description }}</p>

            <!-- Author Information -->
            <div class="blog-author">
              <!-- <img :src="blog.data().authorImage" alt="Author" class="author-image" /> -->
              <span style="font-size: 12px; color: grey">Author : </span>
              <span class="author-name">{{ blog.data().author }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  data() {
    return {
      posts: [],
      // blogs: [
      //   {
      //     image: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     category: "Development",
      //     date: "Oct 14, 2024",
      //     readTime: 5,
      //     title: "How to Build Responsive Web Applications",
      //     description:
      //       "Learn the fundamentals of creating mobile-first, responsive web applications that provide a seamless experience across devices.",
      //     authorImage: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     author: "John Doe",
      //   },
      //   {
      //     image: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     category: "Design",
      //     date: "Sep 30, 2024",
      //     readTime: 7,
      //     title: "The Art of UI/UX Design: Best Practices",
      //     description:
      //       "Explore the key principles of UI/UX design, and how they can help you create user-friendly and visually appealing interfaces.",
      //     authorImage: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     author: "Jane Smith",
      //   },
      //   {
      //     image: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     category: "Marketing",
      //     date: "Aug 22, 2024",
      //     readTime: 4,
      //     title: "Content Marketing Strategies for 2024",
      //     description:
      //       "Stay ahead of the curve with these cutting-edge content marketing strategies to boost engagement and conversions.",
      //     authorImage: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     author: "Michael Johnson",
      //   },
      //   {
      //     image: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     category: "Tech",
      //     date: "Jul 14, 2024",
      //     readTime: 6,
      //     title: "The Future of Artificial Intelligence",
      //     description:
      //       "Discover the latest trends in AI technology, and how it is reshaping industries across the globe.",
      //     authorImage: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     author: "Emily Davis",
      //   },
      //   {
      //     image: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     category: "Business",
      //     date: "Jun 10, 2024",
      //     readTime: 8,
      //     title: "Scaling Startups: Tips for Rapid Growth",
      //     description:
      //       "Learn the best practices for scaling your startup efficiently, from funding strategies to team building.",
      //     authorImage: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     author: "Chris Lee",
      //   },
      //   {
      //     image: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     category: "Health",
      //     date: "May 25, 2024",
      //     readTime: 5,
      //     title: "Maintaining Mental Health in the Digital Era",
      //     description:
      //       "Explore techniques to maintain mental health and well-being in the face of constant digital exposure and work pressures.",
      //     authorImage: "https://images.unsplash.com/photo-1716847214582-d5979adbf300?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      //     author: "Sophia Miller",
      //   },
      // ],
    };
  },
  mounted() {
    this.fetchBlogPosts();
  },
  methods: {
    async fetchBlogPosts() {
      const querySnapShotJobs = await getDocs(collection(db, "blogs"));
      querySnapShotJobs.forEach((element) => {
        console.log("fetch blogs post", element);
        this.posts.push(element);
      });
    },

    getFormattedData(originalDate) {
      const dateObj = new Date(originalDate);

      // Format the date to "Month Day, Year" without time
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return formattedDate;
    },

    handleOnBlogClick(id) {
      this.$router.push({
        name: "blog-detail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.blog-cards-section {
  padding: 40px 20px;
  /* background-color: #ffffff; */
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.blog-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.blog-category {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.blog-details {
  padding: 20px;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
  margin-bottom: 10px;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  text-align: left;
}

.blog-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
  text-align: left;
}

.blog-author {
  display: flex;
  align-items: center;
}

.author-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.author-name {
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .blog-grid {
    grid-template-columns: 1fr;
  }
}
</style>
