<template>
  <header
    style="padding: 0"
    v-if="!$route.meta.hideNavbar"
    id="main-header"
    class="org-menu"
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <li class="nav-item no-bullets">
              <router-link to="/">
                <img
                  class="img-fluid logo"
                  src="./assets/logo.png"
                  alt="img"
                  width="90"
                />
              </router-link>
            </li>

            <!-- <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div class="menu-btn" id="menu-btn">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
              </div>
              <span class="ion-navicon"></span>
            </button> -->

            <button
              class="navbar-toggler"
              type="button"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleNav"
            >
              <div :class="{ 'menu-btn': true, open: isNavOpen }" id="menu-btn">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
              </div>
            </button>

            <div
              :class="['collapse', 'navbar-collapse', { show: isNavOpen }]"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav mr-auto w-100 justify-content-end">
                <!-- <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/solutions"
                    @click="closeNav"
                    >Solutions</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/case-study"
                    @click="closeNav"
                    >Case Studies</router-link
                  >
                </li> -->

                <!-- <li class="nav-item">
                  <router-link class="nav-link" to="/about" @click="closeNav"
                    >About us</router-link
                  >
                </li> -->

                <li class="nav-item">
                  <a class="nav-link" href="/#our_solutions">Solutions </a>
                  <!-- <router-link to="#our_solutions">Solutions</router-link> -->
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="/#our_solutions">About Us </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="/#case_study">Case Studies</a>
                  <!--  <router-link to="/blogs">Case Study</router-link> -->
                </li>

                <!-- <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/careers"
                    @click="closeNav"
                    >Careers</router-link
                  >
                </li> -->
                <!-- <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/blogs"
                    @click="closeNav"
                    >Blogs</router-link
                  >
                </li> -->
                <li class="nav-item">
                  <a class="nav-link" href="/#contact_us" @click="closeNav">
                    <button class="btn btn-primary">CONTACT US</button>
                  </a>
                </li>
              </ul>
            </div>

            <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto w-100 justify-content-end">
                <li class="nav-item">
                  <router-link class="nav-link" to="/about" @click="closeNav"
                    >About us</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/solutions"
                    @click="closeNav"
                    >Solutions</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/case-study"
                    @click="closeNav"
                    >Case Study</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/case-study"
                    @click="closeNav"
                    >Case Study</router-link
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/#contact_us" @click="closeNav">
                    <button class="btn btn-primary">CONTACT US</button>
                  </a>
                </li>
              </ul>
            </div> -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <router-view />
  <Footer v-if="!$route.meta.hideNavbar" />
</template>

<script>
/* eslint-disable */
import Footer from "./components/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      isNavOpen: false, // Track the state of the navbar
    };
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen; // Toggle the navbar state
    },
    closeNav() {
      this.isNavOpen = false; // Ensure the navbar closes
    },
  },
};
</script>

<style>
.header .navbar .navbar-nav .nav-item a {
  color: #105da1;
}

header.org-menu .navbar .navbar-nav .nav-item a::before {
  background: #105da1;
}

header.org-menu .navbar .navbar-nav .nav-item a:hover,
header.org-menu .navbar .navbar-nav .nav-item a:focus,
header.org-menu .navbar .navbar-nav .nav-item a.active,
header.org-menu .navbar .navbar-nav .nav-item a.active:focus,
header.org-menu .navbar .navbar-nav .nav-item a.active:hover {
  color: #135ca4;
}

header.org-menu .navbar-toggler {
  background: #135ca4;
}
</style>
