

<template>
    <section class="section-partners" id="iq-pricing">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h2>Trusted By Thousand businesses</h2>
                        <p>More Than 15,000 companies and partners trusted ThinkAlternate Technologies Pvt. Ltd.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="horizontal-scroll">
        <vue-horizontal responsive>
            <div class="section-item" v-for="item in 20" :key="item.title">
                <img class="img-decoration"
                    src="https://www.freepnglogos.com/uploads/facebook-logo-icon/facebook-logo-icon-facebook-icon-png-images-icons-and-png-backgrounds-1.png"
                    width="100" />

            </div>
        </vue-horizontal>
    </div>
</template>


<script>
/* eslint-disable */
import VueHorizontal from "vue-horizontal";

export default {
    name: 'OurParners',
    components: {
        VueHorizontal
    },
}
</script>


<style>

.item {
    background: #f3f3f3;
    padding: 16px 24px;
    margin-right: 24px;
}

.section-partners {
    padding-bottom: 0;
    padding-top: 0;
}

.section-item {
    padding: 16px 24px;
    margin: 20px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s ease-out;
    box-shadow: 0 2px 10px -4px rgb(0 0 0 / 19%);
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
}

.horizontal-scroll:hover .section-item {
    box-shadow: 3px 2px 3px 3px rgba(190, 227, 249, 0.19);
}

.horizontal-scroll {
    margin-bottom: 120px;
    margin-left: 50px;
    margin-right: 50px;
}
</style>