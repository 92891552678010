<template>
  <section class="pb-0" id="Industries">
    <div class="container">
      <div class="h1">We're not for everyone.</div>
      <div class="h6 mb-5">
        We help startups and midsize businesses in these spaces
      </div>

      <div class="row" style="margin-top: 3rem">
        <div class="col-md-2 col-6 mb-20">
          <div class="d-flex justify-content-center education_technology_hover">
            <img
              src="../assets/home_page_icons/ed_tech_icon.png"
              class="industry-icon"
              alt="Education Technology"
            />
          </div>
          <div class="industry-text">Education<br>Technology</div>
        </div>

        <div class="col-md-2 col-6 mb-20">
          <div class="d-flex justify-content-center retail_technology_hover">
            <img
              src="../assets/home_page_icons/retail_tech.png"
              class="industry-icon"
              alt="Retail Technology"
            />
          </div>
          <div class="industry-text">Retail<br>Technology</div>
        </div>

        <div class="col-md-2 col-6 mb-20">
          <div class="d-flex justify-content-center finance_technology_hover">
            <img
              src="../assets/home_page_icons/fin_tech_icon.png"
              class="industry-icon"
              alt="Finance Technology"
            />
          </div>
          <div class="industry-text">Finance<br>Technology</div>
        </div>

        <div class="col-md-2 col-6 mb-20">
          <div class="d-flex justify-content-center manufacturing_industries_hover">
            <img
              src="../assets/home_page_icons/manufacturing_industries_icon.png"
              class="industry-icon"
              alt="Manufacturing Industries"
            />
          </div>
          <div class="industry-text">Manufacturing<br>Industries</div>
        </div>

        <div class="col-md-2 col-6 mb-20">
          <div class="d-flex justify-content-center fitness_technology_hover">
            <img
              src="../assets/home_page_icons/fitness_tech_icon.png"
              class="industry-icon"
              alt="Fitness Technology"
            />
          </div>
          <div class="industry-text">Fitness<br>Technology</div>
        </div>

        <div class="col-md-2 col-6 mb-20">
          <div class="d-flex justify-content-center co_working_spaces_hover">
            <img
              src="../assets/home_page_icons/co_work_space_icon.png"
              class="industry-icon"
              alt="Co-working spaces"
            />
          </div>
          <div class="industry-text">Co-working<br>spaces</div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center" style="margin-top: 3rem">
      <a
        href="#"
        class="text-uppercase text-primary fw-bold"
        style="color: #105da1; font-weight: 700"
        ><u>View all industries</u></a
      >
    </div>
  </section>
</template>

<script>
/* eslint-disable */
export default {
  name: "WeAreFor",
};
</script>

<style scoped>
.industry-icon {
  width: 80px;
  height: auto;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.industry-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 1rem;
  text-align: center;
  transition: color 0.3s ease;
}

/* Hover effects for icons */
.education_technology_hover:hover img,
.retail_technology_hover:hover img,
.finance_technology_hover:hover img,
.manufacturing_industries_hover:hover img,
.fitness_technology_hover:hover img,
.co_working_spaces_hover:hover img {
  transform: scale(1.1) rotate(5deg);
  filter: brightness(1.2);
}

/* Specific hover image change */
.fitness_technology_hover:hover img {
  content: url("../assets/home_page_icons/fit_tech.svg");
}

.retail_technology_hover:hover img {
  content: url("../assets/home_page_icons/retail_tech.svg");
}

.finance_technology_hover:hover img {
  content: url("../assets/home_page_icons/finance_tech1.svg");
}

.co_working_spaces_hover:hover img {
  content: url("../assets/home_page_icons/co_work_spaces.svg");
}

.manufacturing_industries_hover:hover img {
  content: url("../assets/home_page_icons/manu_industries.svg");
}

.education_technology_hover:hover img {
  content: url("../assets/home_page_icons/ed_tech.svg");
}

/* Text color change on hover */
.education_technology_hover:hover .industry-text,
.retail_technology_hover:hover .industry-text,
.finance_technology_hover:hover .industry-text,
.manufacturing_industries_hover:hover .industry-text,
.fitness_technology_hover:hover .industry-text,
.co_working_spaces_hover:hover .industry-text {
  color: #105da1;
}

</style>
