<!-- <template>
  <div>
    <section class="pb-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <div style="text-align: left">
              <h2 style="font-weight: bold; line-height: 2.7rem">
                Get your hands on a <br />
                working, profitable, and scalable app.
              </h2>
              <h5 style="font-weight: 500 !important; margin-top: 0.8rem">
                Helping Startups and midsize businesses worldwide
              </h5>
              <p style="line-height: 1rem" class="mt-3">
                Grow, scale, and transform your business digitally. With the app
                of your dreams. Built to perfection by team Think Alternate
                Tech.
              </p>

              <ul
                style="
                  color: black;
                  font-size: 13px;
                  list-style-type: none;
                  margin-left: -25px;
                "
              >
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  Experts in Native, Flutter, and React-Native cross-platform
                  app development
                </li>
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  You own the app, code
                </li>
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  Free consultation
                </li>
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  30-days money-back guarantee
                </li>
              </ul>

              <div>
                <a href="#contact_us">
                  <button
                    class="btn btn-primary text-uppercase"
                    style="
                      margin-right: 1rem;
                      width: 15rem;
                      margin-bottom: 15px;
                    "
                  >
                    Build My App
                  </button>
                </a>
                <a href="#case_Study">
                  <button
                    class="btn btn-white color-primary text-uppercase home-past-work-btn"
                    style="width: 16rem; margin-bottom: 15px"
                  >
                    Show me your Past Works
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-lg-0 mb-5">
            <img
              style="max-width: 100%"
              src="../assets/home_page_icons/header_image.svg"
              title=""
            />
          </div>
        </div>
      </div>
    </section>
    <hr style="margin: 50px 50px" class="solid" />

    <section
      v-if="deviceType == 'desktop'"
      style="padding-top: 0"
      class="pb-0 text-dark"
    >
      <div class="container d-flex justify-content-around">
        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bolder h2 show-case-numbers"
          >
            12+ Years
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Industry Leader
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            120+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Clients Served
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            125+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Apps Built
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            100+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Consulting Services
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            11,500+
          </div>
          <div
            class="d-flex justify-content-center text-justify show-case-numbers-title"
          >
            <div>Hours Dedicated to</div>
          </div>
          <div
            class="d-flex justify-content-center text-justify show-case-numbers-title"
          >
            Client's Growth
          </div>
        </div>
      </div>
    </section>

    <div v-else>
      <section style="padding-top: 0" class="pb-0 mt-3 text-dark">
        <div
          class="container d-flex justify-content-center show-case-numbers-title"
        >
          <div class="px-5 show-case-numbers-hover-class">
            <div
              class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
            >
              12+ Years
            </div>
            <div class="d-flex justify-content-center show-case-numbers-title">
              Industry Leader
            </div>
          </div>

          <div class="px-5 show-case-numbers-hover-class">
            <div
              class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
            >
              120+
            </div>
            <div class="d-flex justify-content-center show-case-numbers-title">
              Clients Served
            </div>
          </div>
        </div>
      </section>
      <section style="padding-top: 0" class="pb-0 mt-3 text-dark">
        <div
          class="container d-flex justify-content-center show-case-numbers-title"
        >
          <div class="px-5 show-case-numbers-hover-class">
            <div
              class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
            >
              125+
            </div>
            <div class="d-flex justify-content-center show-case-numbers-title">
              Apps Built
            </div>
          </div>

          <div class="px-5 show-case-numbers-hover-class">
            <div
              class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
            >
              100+
            </div>
            <div class="d-flex justify-content-center show-case-numbers-title">
              Consulting Services
            </div>
          </div>
        </div>
      </section>

      <section style="padding-top: 0" class="pb-0 mt-3 text-dark">
        <div
          class="container d-flex justify-content-center show-case-numbers-title"
        >

          <div class="px-5 show-case-numbers-hover-class">
            <div
              class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
            >
              11,500+
            </div>
            <div class="d-flex justify-content-center show-case-numbers-title">
              Hours Dedicated to<br />
              Client's Growth
            </div>
          </div>
        </div>
      </section>
    </div>

    <section style="padding-top: 0" class="pb-0 mt-3 text-dark">
      <div
        class="container d-flex justify-content-center show-case-numbers-title"
      >
        <div class="px-5 show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            20+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Team Members
          </div>
        </div>

        <div class="px-5 show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            9/10
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Clients Referrals
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import VueCarousel from '@chenfengyuan/vue-carousel';
export default {
  components: {
    // VueCarousel,
  },
  data() {
    return {
      deviceType: window.innerWidth <= 700 ? "mobile" : "desktop",
      data: [
        '<section class="pb-0"><div class="container"><div class="row align-items-center"><div class="col-lg-6"><div style="text-align:left"> <h1>Quality is the best <br>business plan.</h1><p>There are manyvariations of passages ofLorem Ipsum<br> available, but the majority have suffered alterat.</p>   <button class="button" style="background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%)">Read More</button></div></div><div class="col-lg-6 mb-lg-0 mb-5"><img src="https://firebasestorage.googleapis.com/v0/b/that-72d39.appspot.com/o/img-1.9719a644.png?alt=media&token=e4572bf6-f333-4ce7-baa1-4eb6fe581b42" title="" ></div></div></div></section>',
        '<section class="pb-0"><div class="container"><div class="row align-items-center"><div class="col-lg-6"><div style="text-align:left"> <h1>Quality is the best <br>business plan.</h1><p>There are manyvariations of passages ofLorem Ipsum<br> available, but the majority have suffered alterat.</p>   <button class="button" style="background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%)">Read More</button></div></div><div class="col-lg-6 mb-lg-0 mb-5"><img src="https://img.freepik.com/free-vector/bangalore-india-skyline-silhouette-with-panorama-white-background-vector-illustration-business-travel-tourism-concept-with-modern-buildings-image-presentation-banner-website_596401-299.jpg?w=2000" title="46"></div></div></div></section>',
      ],
    };
  },
};
</script>

<style>
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  height: 600px;
}

.vl {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 2px;
  border-left: 3px solid rgb(192, 192, 192);
  height: 25px;
}

.p1 {
  font-size: 15px;
  color: #242627;
  line-height: 1.1;
}

.p2 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  line-height: 1;
}
</style> -->

<template>
  <section class="hero-section">
    <div class="overlay"></div>
    <div class="hero-content">
      <transition name="fade" mode="out-in">
        <h1 class="hero-heading">{{ heroData.title }}</h1>
      </transition>
      <transition name="fade" mode="out-in">
        <h3 class="hero-subheading">{{ heroData.subheading }}</h3>
      </transition>
      <transition name="fade" mode="out-in">
        <p class="hero-subheading2">{{ heroData.subheading2 }}</p>
      </transition>
      <transition name="fade" mode="out-in">
        <div>
          <a href="#contact_us">
            <button
              class="btn btn-primary text-uppercase"
              style="margin-right: 1rem; width: 15rem; margin-bottom: 15px"
            >
              Build My App
            </button>
          </a>
          <a href="#case_Study">
            <button
              class="btn btn-white color-primary text-uppercase home-past-work-btn"
              style="width: 15rem; margin-bottom: 15px; margin-right: 1rem"
            >
              Show me your Past Works
            </button>
          </a>
        </div>
      </transition>
    </div>
  </section>

  <section style="padding-top: 50px;" class="pb-0 text-dark">
  <div class="container">
    <div class="row justify-content-around text-center">
      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bolder h2 show-case-numbers">12+ Years</div>
        <div class="show-case-numbers-title">Industry Leader</div>
      </div>

      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bold h2 show-case-numbers">120+</div>
        <div class="show-case-numbers-title">Clients Served</div>
      </div>

      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bold h2 show-case-numbers">125+</div>
        <div class="show-case-numbers-title">Apps Built</div>
      </div>

      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bold h2 show-case-numbers">100+</div>
        <div class="show-case-numbers-title">Consulting Services</div>
      </div>

      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bold h2 show-case-numbers">11,500+</div>
        <div class="show-case-numbers-title">Hours Dedicated to Client's Growth</div>
      </div>

      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bold h2 show-case-numbers">20+</div>
        <div class="show-case-numbers-title">Team Members</div>
      </div>

      <div class="col-md-4 col-lg-2 col-6 mb-4 show-case-numbers-hover-class">
        <div class="font-weight-bold h2 show-case-numbers">9/10</div>
        <div class="show-case-numbers-title">Clients Referrals</div>
      </div>
    </div>
  </div>
</section>

</template>

<script>
export default {
  data() {
    return {
      heroData: {
        title: "Get your hands on a working, profitable, and scalable app.",
        subheading: "Helping Startups and midsize businesses worldwide",
        subheading2:
          "Grow, scale, and transform your business digitally. With the app of your dreams. Built to perfection by team Think Alternate Tech",
        buttonText: "BUILD MY APP",
      },
    };
  },
};
</script>

<style scoped>
.hero-section {
  position: relative;
  height: 100vh;
  background: url("../assets/home_page_icons/header_image.png") center
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 20px;
}

.hero-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  animation: slideIn 1s ease-out;
}

.hero-subheading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #fff;
  animation: slideIn 1s ease-out;
}
.hero-subheading2 {
  font-size: 1rem;
  margin-bottom: 30px;
  font-weight: 300;
  color: #fff;
  animation: fadeIn 2.5s ease-out;
}

.cta-button {
  padding: 15px 30px;
  background-color: var(--primary-color);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  animation: bounce 2s infinite;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

/* Keyframe Animations */
@keyframes slideIn {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hero-heading {
    font-size: 2.5rem;
  }
  .hero-subheading {
    font-size: 1.2rem;
  }
  .cta-button {
    font-size: 1rem;
  }
}
</style>
