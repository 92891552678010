<template>
  <footer
    class="position-relative org-menu footer-two p-0 custom-footer"
    style="background-color: #001155"
  >
    <div class="container">
      <div class="row footer-links-list">
        <div class="col-3">
          <ul class="list-unstyled">
            <li style="font-weight: 700">Company</li>
            <a style="font-size: 14px; color: white" href="/#case_study"
              >Case Studies</a
            >
          </ul>
        </div>
        <div class="col-3">
          <ul class="list-unstyled">
            <li style="font-weight: 700">Solutions</li>
            <router-link to="/proof-of-concept" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Proof of Concept
              </li></router-link
            >
            <router-link to="/viable-product" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Minimal Viable Product
              </li></router-link
            >
            <router-link to="/product-revamp" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Product Development
              </li></router-link
            >
            <router-link to="/product-revamp" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Product Revamp
              </li></router-link
            >
            <router-link to="/fractional-cto" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Fractional CTO
              </li></router-link
            >
            <router-link to="/mobile-app-development" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Mobile App Support
              </li></router-link
            >
            <router-link to="/mobile-app-development" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Mobile App Maintenance
              </li></router-link
            >
            <router-link to="/app-audit" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Mobile App Audit
              </li></router-link
            >
          </ul>
        </div>

        <div class="col-3">
          <ul class="list-unstyled">
            <li style="font-weight: 700">Services</li>

            <router-link to="/mobile-app-development" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Android App development
              </li></router-link
            >
            <router-link to="/mobile-app-development" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                iOS App development
              </li></router-link
            >
            <router-link to="/mobile-app-development" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Hybrid App development
              </li></router-link
            >

            <router-link to="/web-app-development" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Web App development
              </li></router-link
            >
            <router-link to="/product-design" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Product Design
              </li></router-link
            >
            <router-link to="/software-consulting" style="color: white"
              ><li style="font-size: 14px" class="footer-line-height">
                Software Consulting
              </li></router-link
            >
          </ul>
        </div>

        <img
          style="margin-left: 0%; position: absolute"
          :style="{
            marginLeft: deviceType == 'mobile' ? '0' : '55%',
          }"
          src="../assets/globe.svg"
        />
      </div>
      <div style="margin-top: 5rem; margin-bottom: 3rem">
        <h4 style="color: #fff; margin-bottom: 5px">
          Subscribe to our Newsletter
        </h4>
        <div class="d-flex justify-content-start">
          <input
            v-model="email"
            type="text"
            placeholder="Don't Miss out on Updates"
            style="width: 300px"
          />
        </div>
        <div>
          <button
            @click="handleSubmit"
            class="btn btn-primary text-uppercase mt-3 btn-lg"
            style="width: 9rem; font-size: 15px"
          >
            SEND
            <img
              style="margin-left: 15px"
              src="../assets/icons/arrow_forward.svg"
            />
          </button>
        </div>
      </div>
    </div>

    <div style="background-color: #ffffff">
      <div class="row justify-content-center footer-subscribe">
        <div class="col-lg-12 text-center">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-2">
                <div class="iq-footer-box text-left">
                  <div class="iq-icon">
                    <i aria-hidden="true" class="fa fa-map-marker"></i>
                  </div>
                  <div
                    class="footer-content"
                    style="border-right: #666464 1.5px solid"
                  >
                    <p class="p1" style="font-size: 12px; line-height: 1.5">
                      <span
                        style="
                          font-weight: 600;
                          padding-bottom: 10px;
                          color: #001155;
                        "
                        >Address</span
                      ><br />
                      WeWork, 43, Residency Rd,<br />
                      Bengaluru, 560025
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 r4-mt-30">
                <div class="iq-footer-box text-left">
                  <div class="iq-icon">
                    <i aria-hidden="true" class="fa fa-phone"></i>
                  </div>
                  <div
                    class="footer-content"
                    style="border-right: #666464 1.5px solid"
                  >
                    <p class="p1" style="font-size: 12px; line-height: 1.5">
                      <span style="font-weight: 600; padding-bottom: 10px"
                        >Phone</span
                      ><br />
                      +91 6379232460<br />Mon-Sat 10:00am - 8:00pm<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 r-mt-30">
                <div class="iq-footer-box text-left">
                  <div class="iq-icon">
                    <i aria-hidden="true" class="fa fa-envelope"></i>
                  </div>
                  <div class="footer-content">
                    <p class="p1" style="font-size: 12px; line-height: 1.5">
                      <span style="font-weight: 600; padding-bottom: 10px"
                        >E-mail</span
                      ><br />
                      tech@thinkalternate.com<br />24 X 7 online support<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 r-mt-30">
                <div class="d-flex justify-content-between mt-4">
                  <a
                    href="https://www.linkedin.com/company/thinkalt/"
                    target="_blank"
                  >
                    <div>
                      <img src="../assets/icons/in_icon.svg" />
                      <p class="p1" style="font-size: 12px; margin-top: 5px">
                        LinkedIn
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/thinkalt.co/"
                    target="_blank"
                  >
                    <div>
                      <img src="../assets/icons/instagram.svg" width="25" />
                      <p class="p1" style="font-size: 12px; margin-top: 5px">
                        Instagram
                      </p>
                    </div>
                  </a>
                  <a href="https://twitter.com/ThinkAlt_Co" target="_blank">
                    <div>
                      <img src="../assets/icons/twitter_x.png" width="22" />
                      <p class="p1" style="font-size: 12px; margin-top: 5px">
                        X
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOf63yyitB1P4utlpsN2Hnw"
                    target="_blank"
                  >
                    <div>
                      <img src="../assets/icons/yt_icon.svg" />
                      <p class="p1" style="font-size: 12px; margin-top: 5px">
                        YouTube
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 r-mt-30">
                <div class="d-flex justify-content-center">
                  <img src="../assets/THAT_logo.png" width="120" />
                </div>
              </div>
            </div>
            <div class="row iq-mt-40">
              <div style="margin-bottom: 10px" class="col-sm-12 text-center">
                <div
                  style="
                    font-size: 12px;
                    font-weight: 300;
                    color: rgb(90, 88, 88);
                  "
                >
                  <span
                    style="
                      border: black 1px solid;
                      border-radius: 50%;
                      padding: 1px 3px;
                      font-size: 10px;
                    "
                  >
                    C</span
                  >
                  <span style="margin-left: 2rem">2023 ThinkAlternate </span
                  ><span style="margin-left: 2rem">Terms & Conditions</span>
                  <span style="margin-left: 2rem">Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- back-to-top -->
    <div id="back-to-top" style="">
      <a class="top" id="top" href="#top"> <i class="ion-ios-arrow-up"></i> </a>
    </div>
  </footer>
</template>

<script>
/* eslint-disable */
// import { useToast } from "vue-toastification"; // Import toast
import { Icon } from "@iconify/vue";
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  name: "FooterVue",
  components: {
    Icon,
  },
  data() {
    return {
      email: "", // Bind email input to this variable
      deviceType: window.innerWidth <= 700 ? "mobile" : "desktop",
    };
  },
  methods: {
    // Email validation function
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },

    // Handle the form submission
   async handleSubmit() {
      
      // const toast = useToast(); // Initialize toast
      if (this.isValidEmail(this.email)) {
        
        const colRef = collection(db, "newsletterEmail");
        const dataObj = {
          email: this.email,
          timeStamp: new Date().toLocaleString(),
        };
        const docRef = await addDoc(colRef, dataObj);
        this.email = ""; // Clear the email input after success
        alert("Thank you for subscribing to our newsletter! 🎉 You will receive updates and special offers directly to your inbox.");
      } else {
        alert("Please enter a valid email address.");
      }
    },
  },
};
</script>

<style>
.footer-line-height {
  line-height: 1.25;
  margin-bottom: 1rem;
}

.footer-links-list ul {
  margin-top: 5rem;
  padding-left: 0; /* Adjusts padding to avoid extra width */
}

.footer-links-list li {
  font-size: 16px;
  font-weight: 100;
  line-height: 1.25; /* Adjusting line-height for better readability */
  margin-bottom: 1rem;
}

.custom-footer {
  background-color: #393536;
  color: #ffffff;
  width: 100%; /* Ensures footer stays within the page */
  overflow-x: hidden; /* Prevents horizontal overflow */
}

.footer-content {
  font-size: 12px;
  word-wrap: break-word; /* Prevents long words from breaking layout */
}

@media (max-width: 768px) {
  .footer-links-list .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem; /* Adds spacing between columns on smaller screens */
  }
  .footer-links-list ul {
    margin-top: 3rem;
  }
}
</style>
