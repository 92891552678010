<template>
  <section
    class="pb-0 section-bg-color"
    id="our_solutions"
    style="padding-top: 3rem"
  >
    <div class="container mb-5">
      <div
        v-if="showButton"
        class="h3"
        style="padding-bottom: 3rem; color: #001155"
      >
        We're not just app builders, We are More than that!
      </div>
      <!-- Looping through solution items -->
      <div class="row mb-3">
        <div
          v-for="(solution, index) in solutionsList"
          :key="index"
          class="col-md-4 col-sm-12 mb-20"
        >
          <a :href="solution.link" style="color: #000">
            <div :class="`card solutions-card-background-hover ${solution.hoverClass}`">
              <div
                class="card-body solutions-card-background"
                style="height: 16rem"
              >
                <div>
                  <img
                    :src="solution.iconSrc"
                    style="max-width: 60px; min-width: 60px; margin-bottom: 2rem;"
                  />
                </div>
                <span class="h6 solutions-card-heading">{{ solution.title }}</span>
                <p class="h6 paragraph-line-height">
                  {{ solution.description }}
                </p>
              </div>
              <div
                class="card-footer text-muted solutions-card-background solutions-card-background"
              >
                Learn More
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 50px" class="container">
      <div class="d-flex justify-content-center">
        <router-link to="solutions">
          <button
            v-if="showButton"
            class="btn btn-primary text-uppercase mb-5"
          >
            View All Solutions
          </button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
// import { ref } from "vue";

export default {
  name: "solutions",
  props: ["showButton"],
  data() {
    return {
      // Define solutionsList data to loop through
      solutionsList: [
        {
          link: "proof-of-concept",
          iconSrc: require( "../assets/icons/proof_of_concept.svg"),
          title: "Proof of Concept",
          description:
            "Medium size business at their growth stage build a prototype and test the model out in the market to identify new channels of revenue.",
          hoverClass: "proof_of_concept_hover",
        },
        {
          link: "viable-product",
          iconSrc: require("../assets/icons/minimal_viable_product.svg"),
          title: "Minimal Viable Product Development",
          description:
            "Early stage Start-ups build a MVP to test the product market fit (PMI) and GTM strategy.",
          hoverClass: "minimal_viable_product_hover",
        },
        {
          link: "product-revamp",
          iconSrc: require("../assets/icons/full_throttle_product.svg"),
          title: "Product Revamp",
          description:
            "Start-ups, Family run Biz and SMB's as they grow their business they are on the lookout for technical teams.",
          hoverClass: "full_throttle_product_hover",
        },
        {
          link: "fractional-cto",
          iconSrc: require("../assets/icons/fractional_cto.svg"),
          title: "Fractional CTO",
          description:
            "Tech enabled or Tech driven start-up who do not have a technical head or co-founder mandatory need this service.",
          hoverClass: "fractional_cto_hover",
        },
        {
          link: "app-maintenance",
          iconSrc: require("../assets/icons/mobile_app_support.svg"),
          title: "Mobile App Support and Maintenance",
          description:
            "Startups and SMB’s who already have a product but do not have the right team to work on issue fixes, upgrades and new feature updates.",
          hoverClass: "mobile_app_support_hover",
        },
        {
          link: "app-audit",
          iconSrc:require( "../assets/icons/app_audit.svg"),
          title: "Mobile App Audit",
          description:
            "Startups and SMB’s who already have a product but are unhappy with its performance and it’s functionality is critical to the business.",
          hoverClass: "app_audit_hover",
        },
      ],
    };
  },
};
</script>


<style>
/* .section-bg-color {
  background: linear-gradient(
    179.48deg,
    #4e99de -2.76%,
    #b7cee3 -2.76%,
    #b7cee3 -2.76%,
    #96b8d5 -2.75%,
    #b7cee3 31.68%,
    #cfdfec 63.29%,
    #f3f8fc 99.87%
  );
}

.solutions-card-background-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.solutions-card-background-hover:hover {
   border-radius: 10px;
  transform: scale(1.05);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.proof_of_concept_hover:hover img {
  content: url("../assets/icons/proof_of_concept_hover.svg");
}

.full_throttle_product_hover:hover img {
  content: url("../assets/icons/full_throttle_product_hover.svg");
}

.app_audit_hover:hover img {
  content: url("../assets/icons/app_audit_hover.svg");
}

.minimal_viable_product_hover:hover img {
  content: url("../assets/icons/minimal_viable_product_hover.svg");
}

.fractional_cto_hover:hover img {
  content: url("../assets/icons/fractional_cto_hover.svg");
}

.mobile_app_support_hover:hover img {
  content: url("../assets/icons/mobile_app_support_hover.svg");
} */


.proof_of_concept_hover:hover img {
  content: url("../assets/icons/proof_of_concept_hover.svg");
}

.full_throttle_product_hover:hover img {
  content: url("../assets/icons/full_throttle_product_hover.svg");
}

.app_audit_hover:hover img {
  content: url("../assets/icons/app_audit_hover.svg");
}

.minimal_viable_product_hover:hover img {
  content: url("../assets/icons/minimal_viable_product_hover.svg");
}

.fractional_cto_hover:hover img {
  content: url("../assets/icons/fractional_cto_hover.svg");
}

.mobile_app_support_hover:hover img {
  content: url("../assets/icons/mobile_app_support_hover.svg");
}

.section-bg-color {
  background: linear-gradient(
    179.48deg,
    #4e99de -2.76%,
    #b7cee3 -2.76%,
    #b7cee3 -2.76%,
    #96b8d5 -2.75%,
    #b7cee3 31.68%,
    #cfdfec 63.29%,
    #f3f8fc 99.87%
  );
}

.solutions-card-background-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.solutions-card-background-hover:hover {
  transform: translateY(-8px);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
  color: #ffffff;
}

.card-body {
  text-align: center;
  padding: 2rem;
}

.card-footer {
  background-color: #f1f7fc;
  text-align: center;
  padding: 1rem;
  font-weight: bold;
  border-top: 1px solid #e0e8f0;
}

h3 {
  color: #1e2952;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

h6.solutions-card-heading {
  color: #001155;
  font-weight: 600;
  margin-bottom: 1rem;
}

.paragraph-line-height {
  /* color: #666666; */
  font-size: 0.9rem;
  line-height: 1.5;
  margin-top: 1rem;
}

img {
  transition: transform 0.3s ease;
}

.card-body img {
  display: block;
  margin: 0 auto 1rem auto;
}

.card-body:hover img {
  transform: scale(1.1);
}


@media (max-width: 768px) {
  .h3 {
    font-size: 1.5rem;
  }

  .card-body {
    padding: 1.5rem;
  }

  .paragraph-line-height {
    font-size: 0.85rem;
  }

  .btn-primary {
    width: 100%;
    padding: 1rem;
  }
}




</style>
