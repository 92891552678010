<template>
  <div class="testimonial-section">
    <!-- Left Section (Image, Company Name, Description, and List) -->
    <div class="testimonial-card left-card">
      <transition name="slide-horizontal" mode="out-in">
        <div
          class="card-content company-info"
          v-if="showHorizontal"
          :key="activeIndex"
        >
          <img
            :src="testimonials[activeIndex].companyLogo"
            alt="Company Logo"
            class="company-image"
          />
          <h4 style="font-weight: bold">
            {{ testimonials[activeIndex].companyName }}
          </h4>
          <h6 style="margin-bottom: 10px; font-weight: 500">
            {{ testimonials[activeIndex].title }}
          </h6>
          <p v-html="testimonials[activeIndex].message"></p>
          <ul class="company-points">
            <li
              v-for="(point, index) in testimonials[activeIndex].points"
              :key="index"
            >
              {{ point }}
            </li>
          </ul>
          <a href="#contact_us">
            <button
              style="background-color: var(--primary-color); color: #ffffff"
              class="btn btn-primary text-uppercase b-width"
              @click="handleClick(index)"
            >
              TELL ME HOW YOU DID IT
            </button>
          </a>
        </div>
      </transition>
    </div>

    <!-- Right Section (Person Image, Name, and Description) -->
    <div class="testimonial-card right-card">
      <transition name="slide-vertical" mode="out-in">
        <div
          class="card-content person-info"
          v-if="showVertical"
          :key="activeIndexVertical"
        >
          <div class="person-header">
            <img
              :src="testimonialsAuthorDetails[activeIndexVertical].authorImage"
              alt="Person"
              class="person-image"
            />
            <div>
              <h5 style="font-weight: 700">
                {{ testimonialsAuthorDetails[activeIndexVertical].author }}
              </h5>
              <h3 style="font-weight: 500; font-size: 17px">
                {{
                  testimonialsAuthorDetails[activeIndexVertical]
                    .authorDesignation
                }}
              </h3>
            </div>
          </div>
          <p class="description">
            {{ testimonialsAuthorDetails[activeIndexVertical].authorWords }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          companyLogo: require("../assets/images/2.png"), // Example image URL
          companyName: "Mental Health & Fitness",
          title:
            "A user-friendly Mobile App. An Operations friendly Web App. A Responsive Website.",
          message:
            "iDare transformed the mental health industry by helping people recognize, prevent and fight against abuse with a holistic app. <br> iDare saved a Kashmiri teenager from domestic abuse with this App!",
          points: [
            "93K App Launches",
            "4 mins 5 sec Avg Engagement time",
            "8K + unique global downloads",
          ],
        },
        {
          companyLogo: require("../assets/home_page_icons/hoc_icon.svg"),
          companyName: "Luxury eCommerce",
          title:
            "The ULTIMATE, tailor-made, and secure Web eCommerce Marketplace..",
          message:
            "House of creativity takes the art industry online by introducing one of a kind design and highly secure marketplace. From guest checkout to insuring art on transit everything is taken care.The concept completely transforms the way art is purchased.<br>Customers have bought single artwork as high as $5K USD!",
          points: [
            "Design Driven Development",
            "Responsive Web Ap",
            "Multi-currency and global shipment",
          ],
        },
        {
          companyLogo: require("../assets/images/flash_n_tap_logo.svg"),
          companyName: "Retail Technology",
          title:
            "Game- Changing, light-weight, user friendly and cost effective mobile app.",
          message:
            "One of the most innovative products in Canada. It is an IoT based product with an IoT device, mobile and web app. Customers visiting places like Target or Walmart stores can tap their phone to receive invoices, track expenses and showcase saving and cheaper alternative stores.",
          points: [
            "Native Android and iOS apps",
            "BLE and NFC technology",
            "IBM backed product",
          ],
        },
        {
          companyLogo: require("../assets/images/pathfinder_logo.svg"),
          companyName: "PharmaTech",
          // title: "Game- Changing, light-weight, user friendly and cost effective mobile app.",
          message:
            "Pathfinder Business intelligence analytics can fundamentally disrupt current disease management, allowing a higher degree of predictability in care delivery and better insights into resource allocation.",
          points: [
            "Quick Scalable prototype",
            "Cross-platform Mobile App.",
            "Flutter Framework.",
          ],
        },
        {
          companyLogo: require("../assets/images/ambimat_logo.png"),
          companyName: "IoT Technology",
          // title: "Game- Changing, light-weight, user friendly and cost effective mobile app.",
          message:
            "Ambimat’s smart heat sensor technology helps various businesses in view and recording temperature of cold storage, hot storage and other temperature sensitive products. The app uses BLE and NFC technology for communication..",
          points: [
            "Hybrid App with heat sensor IOT Module.",
            "Xamarin Mobile App Framework",
          ],
        },
      ],

      testimonialsAuthorDetails: [
        {
          author: "Vaamaa Baldota",
          authorDesignation: "CEO, iDare Bengaluru",
          authorWords:
            "Good Team! Committed to your mission! Quality of work! The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds.Really enjoyed working with ThinkAlt! Would highly recommend them.",
          authorImage: require("../assets/client_images/idare.png"),
        },
        {
          author: "Luv Sinha",
          authorDesignation: "CEO, House of Creativity Mumbai- India",
          authorWords:
            "The team was helpful and went out of their way at times. Their support and efficiency are the reasons why we would recommend them. Their support and efficiency are the reasons why we would recommend them.",
          authorImage: require("../assets/images/house_of_creativity.png"),
        },
        {
          author: "Venkata G Thota",
          authorDesignation: "COO, Apeirogon Labs Canada",
          authorWords:
            "Technically strong team that listen and understand what we want and timely deliverables. App is successfully completed with backend support. Friendly knowledge transfer to our in-house team. Top 3 reasons to recommend- Talent, Behavior and Focus",
          authorImage: require("../assets/images/apeir_labs.png"),
        },
        {
          author: "Anand Srinivasan",
          authorDesignation: "Weathervane Pte Ltd",
          authorWords:
            "They are an Exceptional team with clear leadership. We are impressed by their commitment, speed and knowledge. Quality of delivery, Design skills and Seamless integration of desktop to mobile environment are the reasons for our success story.",
          authorImage: require("../assets/images/profile_placeholder.png"),
        },
        {
          author: "Neil Shah",
          authorDesignation: "Ambimat Electronics",
          authorWords:
            "They have a good core team and access to developers with a variety of skill set. ThinkAlt has a good team! Keep up the good work. Unexpected benefit is the fact that a Xamarin developer was available with ThinkAlt",
          authorImage: require("../assets/images/profile_placeholder.png"),
        },
      ],
      activeIndex: 0,
      activeIndexVertical: 0,
      showHorizontal: true,
      showVertical: true,
    };
  },
  mounted() {
    this.startSlideshow();
  },
  methods: {
    startSlideshow() {
      // Slide text horizontally every 3 seconds
      setInterval(() => {
        this.showHorizontal = false;
        setTimeout(() => {
          this.activeIndex = (this.activeIndex + 1) % this.testimonials.length;
          this.showHorizontal = true;
        }, 500); // Adjust for animation timing
      }, 3000);

      // Slide text vertically every 3 seconds
      setInterval(() => {
        this.showVertical = false;
        setTimeout(() => {
          this.activeIndexVertical =
            (this.activeIndexVertical + 1) % this.testimonials.length;
          this.showVertical = true;
        }, 500); // Adjust for animation timing
      }, 3000);
    },
  },
};
</script>

<style scoped>
.testimonial-section {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  margin-top: 100px !important;
  width: 90%;
  flex-wrap: wrap; /* Makes cards stack on mobile */
}

.testimonial-card {
  width: 48%; /* 45% on larger screens */
  height: auto;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

.card-content {
  width: 100%;
}

/* Left card company info */
.company-info {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items vertically */
}

.company-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

h4 {
  margin: 10px 0;
}

.company-points {
  list-style-type: disc;
  padding-left: 20px;
}

/* Right card person info */
.person-info {
  text-align: left;
  font-weight: 400;
}

.person-header {
  display: flex;
  align-items: center;
}

.person-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.description {
  margin-top: 10px;
  font-size: 1em;
}

/* Horizontal slide animation for text */
.slide-horizontal-enter-active,
.slide-horizontal-leave-active {
  transition: transform 0.5s ease;
}
.slide-horizontal-enter {
  transform: translateX(100%);
}
.slide-horizontal-leave-to {
  transform: translateX(-100%);
}

/* Vertical slide animation for text */
.slide-vertical-enter-active,
.slide-vertical-leave-active {
  transition: transform 0.5s ease;
}
.slide-vertical-enter {
  transform: translateY(100%);
}
.slide-vertical-leave-to {
  transform: translateY(-100%);
}

h3,
h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

p {
  font-size: 1em;
  margin-bottom: 10px;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .testimonial-section {
    flex-direction: column; /* Stack cards on mobile */
  }

  .testimonial-card {
    width: 100%; /* Full width on mobile */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  h3,
  h4 {
    font-size: 1.1em; /* Slightly smaller heading for mobile */
  }

  p {
    font-size: 0.95em; /* Slightly smaller paragraph text */
  }
}
</style>
